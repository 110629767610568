import React, { Suspense } from "react";
import { Router, Route, Switch, Redirect, Navigate } from "react-router-dom";
import { hydrate, render } from "react-dom";
import TagManager from "react-gtm-module";

import Cookies from "js-cookie";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
import history from "./history";

// Import Styles
import "./css/Base.scss";

// Import Components
import Modal from "./components/shared-ui/Modals/Modal";
import TermsModal from "./components/shared-ui/Modals/TermsModal";
import CookiesBar from "./components/CookiesBar";
import ScrollToTop from "./ScrollToTop";

// ITP PAGES
import ITPHome from "./pages/ITP/ITPHome";
import WhatIsITP from "./pages/ITP/WhatIsITP";
import SignsAndSymptoms from "./pages/ITP/SignsAndSymptoms";
import DiagnosisAndTreatment from "./pages/ITP/DiagnosisAndTreatment";
import TalkToYourDoctor from "./pages/ITP/TalkToYourDoctor";
import AboutDoptelet from "./pages/ITP/AboutDoptelet";
import ConsideringDoptelet from "./pages/ITP/ConsideringDoptelet";
import ManagingITP from "./pages/ITP/ManagingITP";
import SupportAndResources from "./pages/ITP/SupportAndResources";
import ThankYou from "./pages/ITP/ThankYou";
import RecruitThankYou from "./pages/ITP/RecruitThankYou";
import CLDHome from "./pages/CLD/CLDHome";
import NotFound from "./pages/NotFound";
import SmsTerms from "./pages/SmsTerms";
import Unsubscribe from "./pages/unsubscribe";
import FinancialAssistance from "./pages/ITP/FinancialAssistance";
import VideoResources from "./pages/ITP/VideoResources";
import PatientResources from "./pages/ITP/PatientResources";
import GetGoalGettersTips from "./pages/ITP/GetGoalGettersTips";
import HearFromARealPatient from "./pages/ITP/HearFromARealPatient";
import ShareYourStory from "./pages/ITP/ShareYourStory";
import FAQ from "./pages/ITP/FAQ";
import DrugLandingPage from "./pages/DrugLandingPage";

const tagManagerArgs = {
  gtmId: "GTM-5ZXSMJH",
};

function hideModalIfOpen(action) {
  // PUSH is for page change. POP is for hash change (i.e. clicking the terms modal);
  if (action === 'PUSH') {
    const modals = document.querySelectorAll('.modal-modal');
    for (let modal of modals) {
      if (modal.classList.contains('fadeIn')) {
        modal.querySelector('.exitBtn').click();
      }
    }
  }
}

//this is to determine whether the recaptcha should show, componentDidUpdate and componenetDidMount weren't working for it
history.listen((location, action) => {
  hideModalIfOpen(action);
  if (location.pathname !== '/cld/') {
    const cookieBar = document.getElementById('cookie-bar');
    cookieBar.dataset.isCld = -1;
  }
});

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      cookieBarVisible: true,
    };
  }
  componentDidMount() {
    window.history.scrollRestoration = 'manual';
    if (!Cookies.get("CookieConsent")) {
      this.setState({
        cookieBarVisible: true,
      });
      document.querySelectorAll("div .home_hero .flex-wrapper .col .btn-info").forEach(el => {
        el.classList.add("translate")
      });
    }
    else {
      this.setState({
        cookieBarVisible: false,
      });
      document.querySelectorAll("div .home_hero .flex-wrapper .col .btn-info").forEach(el => {
        el.classList.remove("translate")
      });
    }
  }

  render() {
    let cookieBarShowing = this.state.cookieBarVisible
      ? "show-cookie-bar"
      : "hide-cookie-bar";
    return (
      <div className={`App ${cookieBarShowing} `} id="OuterApp">
        <Modal />
        <TermsModal />
        <Router history={history}>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route
                exact
                path="/index.html"
                component={ITPHome}
              />
              <Route
                exact
                path="/"
                component={ITPHome}
              />
              <Route
                exact
                path="/home/"
                component={ITPHome}
              />
              <Route
                exact
                path="/what-is-itp/"
                component={WhatIsITP}
              />
              <Route
                exact
                path="/what-is-itp/itp-signs-and-symptoms/"
                component={SignsAndSymptoms}
              />
              <Route
                exact
                path="/what-is-itp/itp-diagnosis-and-treatment"
                component={DiagnosisAndTreatment}
              />
              <Route
                exact
                path="/what-is-doptelet/"
                component={AboutDoptelet}
              />
              <Route
                exact
                path="/what-is-doptelet/why-doptelet/"
                component={ConsideringDoptelet}
              />
              <Route
                exact
                path="/managing-itp/"
                component={ManagingITP}
              />
              <Route
                exact
                path="/talk-to-your-doctor/"
                component={TalkToYourDoctor}
              />
              <Route
                exact
                path="/support-and-resources/"
                component={SupportAndResources}
              />
              <Route
                exact
                path="/support-and-resources/financial-assistance/"
                component={FinancialAssistance}
              />
              <Route
                exact
                path="/support-and-resources/chronic-itp-videos/"
                component={VideoResources}
              />
              <Route
                exact
                path="/support-and-resources/living-with-itp/"
                component={GetGoalGettersTips}
              />
              <Route
                exact
                path="/support-and-resources/itp-patient-stories/"
                component={HearFromARealPatient}
              />
              <Route
                exact
                path="/support-and-resources/itp-patient-resources/"
                component={PatientResources}
              />
              <Route
                exact
                path="/support-and-resources/share-your-story/"
                component={ShareYourStory}
              />
              <Route
                exact
                path="/chronic-itp-faqs/"
                component={FAQ}
              />
              <Route
                exact
                path="/avatrombopag-tpo-ra/"
                component={DrugLandingPage}
              />
              <Route path="/themes/pdf/prescribing-information.pdf" component={() => {
                window.location.replace('/themes/pdf/prescribing-information.pdf');
                return null;
              }} />

              <Route exact path="/thank-you/" component={ThankYou} />
              <Route exact path="/recruit-thank-you/" component={RecruitThankYou} />
              <Route exact path="/cld/" component={CLDHome} />

              <Route path="/patient/copay-assistance" component={() => {
                window.location.replace('https://www.activatethecard.com/8015/?src=patient');
                return null;
              }} />
              <Route exact path="/unsubscribe/" component={Unsubscribe} />
              <Route exact path="/sms-terms/" component={SmsTerms} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
          <CookiesBar />
        </Router>
      </div>
    );
  }
}

if (window.navigator.userAgent !== 'ReactSnap') {
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<App />, document.getElementById("root"));

// React-Snap to generate static html files.
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
