import React from "react";
import { Container } from "react-bootstrap";

import "../../../css/Footer.scss";
import { Link } from "react-router-dom";

import { pushOutboundClick } from "../../../assets/datalayerFunctions";


class Footer extends React.Component {


  render() {
    return (
      <footer className="main-footer" id="footer">
        <div className="footer-container">
          <Container>
            <div className="footer-content">
              <div className="footer_logo">
                <Link
                  to={{ pathname: "https://sobi-northamerica.com/" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={pushOutboundClick}
                >
                  <img
                    alt="Sobi Logo"
                    src="/images/sobi-logo.png"
                  />
                </Link>
              </div>
              <div className="footer_menu">
                <div className="footer-link">
                  <Link
                    to={{ pathname: "https://sobi-northamerica.com/terms-use" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={pushOutboundClick}
                  >
                    Terms of Use
                  </Link>
                </div>
                <div className="footer-link">
                  <Link
                    to={{ pathname: "https://sobi-northamerica.com/site-privacy-policy " }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={pushOutboundClick}
                  >
                    Privacy Policy
                  </Link>
                </div>
                <div className="footer-link">
                  <Link
                    to={{ pathname: "https://sobi-northamerica.com/contact-us " }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={pushOutboundClick}
                  >
                    Contact Us
                  </Link>
                </div>
                <div className="footer-link">
                  <Link
                    to={{
                      pathname: "/unsubscribe/",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={pushOutboundClick}
                  >
                    Unsubscribe
                  </Link>
                </div>

                <div>

                </div>
              </div>

              <div className="copyright-info">
                <p>
                  Doptelet is a registered trademark of AkaRx, Inc.<br />
                  Sobi is a trademark of Swedish Orphan Biovitrum AB (publ)<br />
                  &copy; 2021 Sobi, Inc. - All rights reserved
                </p>
                <p>
                  PM-US-DOP-0380. September 2021<br />
                  For US Residents Only
                </p>
              </div>

            </div>
          </Container>
        </div>
      </footer>
    );
  }
}

export default Footer;
