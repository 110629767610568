import React, { useState, useEffect } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

import "../../../css/Accordion.scss";

function pushAccordionClick(accordionTitle) {
  window.dataLayer.push({
    event: "e_interaction",
    eventCategory: "Interaction",
    eventAction: `Click-In Site`,
    eventLabel: `${accordionTitle}`,
  });
}

function CustomAccordion(props) {
  const [isAccordionOpen, setIsAccordianOopen] = useState(false);
  useEffect(() => {
    if (isAccordionOpen === true) {
      let accordionTitle = props.title;
      pushAccordionClick(accordionTitle);
    }
  }, [props.defaultActiveKey, isAccordionOpen, props.title]);

  useEffect(() => {
    if (props.defaultActiveKey == 0) {
      setIsAccordianOopen(true);
    }
  }, []);
  return (
    <div>
      <Accordion defaultActiveKey={props.defaultActiveKey} id={props.id} className={props.className}>
        <Card>
          <Card.Header data={props.title}>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsAccordianOopen(!isAccordionOpen);
              }}
            >
              {props.title}
              {isAccordionOpen ? (
                <FontAwesomeIcon icon={faMinus} />
              ) : (
                <FontAwesomeIcon icon={faPlus} />
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {props.bodyText ? (
                <p>{props.bodyText}</p>
              ) : ''}
              {props.bodyText2 ? (
                <p>{props.bodyText2}</p>
              ) : ''}
              {props.markup ? (
                <>{props.markup}</>
              ) : ''}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default CustomAccordion;
