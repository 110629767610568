import React from "react";
import { Container } from "react-bootstrap";

// Import Components
import ItpNavigation from "../components/ITPNavigation/ITPNavigation";
import FooterITP from "../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../components/shared-ui/ISI/ISIITP";
import Helmet from "react-helmet";

function SmsTerms() {
  return (
    <div className="main_content_wrap unsubscribe">
      <Helmet>
        <title>Doptelet® SMS Terms of Service | DOPTELET® (avatrombopag)</title>
      </Helmet>
      <div className="main_content_wrap">
        <ItpNavigation />
        <div className="inner-content-wrap">
          <section>
            <Container>
              <br />
              <br />
              <h2>Terms of Service</h2>
              <p>When you opt in to the service, we will send a message to confirm your signup. You will receive no more than 1 message/week. Message and data rates may apply.</p>

              <p>You can cancel this service at any time by texting <strong>"STOP"</strong> to <strong>62314</strong>. We will send a reply message to confirm that you have been unsubscribed. After this, you will no longer receive messages from us.</p>

              <p>If you forget what keywords are supported, text <strong>"HELP"</strong> to <strong>62314</strong>. We will respond with instructions on how to use our service as well as how to unsubscribe.</p>

              <p>Participating Tier 1 Carriers: AT&T, Sprint/Boost/Virgin, T-Mobile, MetroPCS, Verizon Wireless. T-Mobile is not liable for delayed or undelivered messages.</p>

              <p>If you have any questions about your text plan or data plan, it is best to contact your wireless provider.</p>

              <p>For all questions about the services provided by this short code, you can send an email <a href="mailto:info@sobi.com">info@sobi.com</a>, call <a href="tel:+18005555555">800-555-5555</a> or visit <a href="https://sobi-northamerica.com/contact-us" target="_blank" rel="noopener noreferrer">https://sobi-northamerica.com/contact-us</a>.</p>

              <p>If you have any questions regarding privacy, please read our Privacy Policy at <a href="https://sobi-northamerica.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://sobi-northamerica.com/privacy-policy</a>.</p>
              <br />
              <br />
            </Container>
          </section>
          <ISIITPinline />
        </div>
        <FooterITP pp="PP-16084. 09/22" copyyear="2022" copyright="Doptelet is a registered trademark of AkaRx, Inc., a Sobi Company" />
        <ISIwrapper>
          <ISIITP />
        </ISIwrapper>
        <BackToTopBtn />
      </div>
    </div>
  );
}
export default SmsTerms;
