import React, { useState } from "react";
import { Container, Dropdown, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpeakerButton from  "../shared-ui/Buttons/SpeakerButton"

import "../../css/ITPNavigation.scss";
import {
  pushDownloadEvent ,
  pushIndicationClick ,
  pushLogoClick ,
  pushOutboundClick
} from "../../assets/datalayerFunctions";

function ErrorNavigation(props) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <header className={`header ${isNavOpen ? "navOpen" : ""}`}>
      <div className="utility-nav">
        <Container>
          <ul className="utility-nav-wrapper error-utility-nav-wrapper">
            <li>
              <Link
                to="#isi"
                onClick={() => {
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                  setTimeout(function () {
                    document.getElementById("expand").click();
                  }, 350);
                }}
              >
                Important Safety Information
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "/themes/pdf/prescribing-information.pdf",
                }}
                target="_blank"
                onClick={(event) => {
                  pushDownloadEvent(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                Prescribing Information
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "/themes/pdf/patient-brief-summary.pdf",
                }}
                target="_blank"
                onClick={(event) => {
                  pushDownloadEvent(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                Important Facts
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "https://doptelethcp.com/" }}
                target="_blank"
                onClick={(event) => {
                  pushOutboundClick(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                For Physicians
              </Link>
            </li>
            <li>
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  Select Condition
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/home/" onClick={pushIndicationClick}>
                    Immune Thrombocytopenia
                  </Dropdown.Item>
                  <Dropdown.Item href="/cld/" onClick={pushIndicationClick}>
                    Chronic Liver Disease
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Container>
      </div>

      <div className="nav_wrap">
        <Container className="menu-container">
          <Row className="align-items-center">
            <Col xs={3} className="logo-wrapper">
              <Link to="/home/" onClick={(event) => {
                pushLogoClick(event);
                if (
                  document
                    .querySelector(".header")
                    .classList.contains("navOpen")
                ) {
                  setIsNavOpen(!isNavOpen);
                  document
                    .querySelector("body")
                    .classList.contains("nav-open")
                    ? document
                      .querySelector("body")
                      .classList.remove("nav-open")
                    : document
                      .querySelector("body")
                      .classList.add("nav-open");
                }
              }}>
                <img
                  alt="Doptelet avatrombopag Tablets Logo"
                  src="/images/doptelet-avatrombopag-tablets-logo.svg"
                />
              </Link>
              <div className="speaker-button">
                <SpeakerButton/>
              </div>
            </Col>
            <Col xs={9}>
              <div className="menu_wrap"></div>
            </Col>
          </Row>
          <div
            className="menu_btn"
            onClick={() => {
              setIsNavOpen(!isNavOpen);
              document.querySelector("body").classList.contains("nav-open")
                ? document.querySelector("body").classList.remove("nav-open")
                : document.querySelector("body").classList.add("nav-open");
            }}
          >
            <span></span>
            <span></span>
          </div>
        </Container>
      </div>
      <div
        className="open-nav-overlay"
        onClick={() => {
          setIsNavOpen(!isNavOpen);
          document.querySelector("body").classList.contains("nav-open")
            ? document.querySelector("body").classList.remove("nav-open")
            : document.querySelector("body").classList.add("nav-open");
        }}
      ></div>
    </header>
  );
}

export default ErrorNavigation;
