import React from "react";

import "../../../css/BackToTopBtn.scss";

class BackToTopBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showScrollToTopBtn: false,
		};
		this.handleScroll = this.handleScroll.bind(this);
	}
	clickToTop(){
		window.scrollTo({top: 0, behavior: 'smooth'});
	};
	handleScroll(e){
		let scrollTop = this.returnScrollTop();
		
		// SCROLL TO TOP BUTTON FUNCTIONALITY
		if (Math.round(scrollTop) >= 500){
			this.setState({showScrollToTopBtn: true});
		} else {
			this.setState({showScrollToTopBtn: false});
		}
	};
	returnScrollTop(){
		return (window.scrollY || document.documentElement.scrollTop);
	};
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, { passive: true });
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}
	
	render() {
		const showScrollBtn = this.state.showScrollToTopBtn ? 'show' : '';
		return (
			<div className={`scroll_btn ${showScrollBtn}`} onClick={this.clickToTop}>
				<img src="/images/scroll_btn.png" alt="Scroll To Top Button" />
			</div>
		);
	}
}

export default BackToTopBtn;
