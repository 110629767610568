import React, {useState} from "react";
import { Button } from "react-bootstrap";
import speakerIcon from "../../../assets/images/doptelet-pronunciation-speaker-button.svg"

function SpeakerButton({
  href,
  target,
  rel,
}) {
   
  const [isActive, setIsActive] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)
  const [currentAudio, setcurrentAudio] = useState(1)
  const [disabled, setDisabled] = useState(false)
   
  const toggleAudio = (e) => {
    
    var audio = new Audio('/audio/audio'+currentAudio+'.mp3'); 
    setIsActive(true);
    setDisabled(true);
    audio.play();
    audio.onended=function() {
      setIsActive(false);
      setMouseOver(false);
      setDisabled(false);
    }
    if(currentAudio >= 8) {
      setcurrentAudio(1);
    } else {
      setcurrentAudio(currentAudio + 1);
    }
    
  }
      
  const addMouseOver = (e) => {
    setMouseOver(true)
  }
  const removeMouseOver = (e) => {
    setMouseOver(false)
  }
     

  return (
      <>
        <Button
          onClick={toggleAudio}
          variant="primary"
          disabled={`${disabled ? "disabled" : ""}`}
          className={`btn-speaker ${isActive ? "active" : ""} ${mouseOver ? "mouseOver" : "" }`}
          href={href}
          target={target}
          rel={rel}
          onMouseOver={addMouseOver}
          onMouseLeave={removeMouseOver}
          onTouchEnd={removeMouseOver}
        >
          <img src={speakerIcon} alt="Doptelet Pronunciation Speaker Button" className="speaker-icon"/>
        </Button>
    </>
  );
}

export default SpeakerButton;
