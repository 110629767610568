import React from "react";
import { Container } from "react-bootstrap";

import "../../../css/ISI.scss";

export default function ISI() {
  return (
    <div className="isi-wrapper">
      <div className="expansion_content text-left">
        <Container>
          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>
                What is DOPTELET<sup>&reg;</sup> <span class="fw-normal">(avatrombopag)</span>?
              </strong>
            </p>
            <p>
              DOPTELET is a prescription medicine used to treat low blood platelet counts in adults with:
            </p>
            <ul className="padding-bottom">
              <li>
                long-lasting (chronic) liver disease (CLD) who are scheduled to
                have a medical or dental procedure.
              </li>
              <li>
                chronic immune thrombocytopenia (ITP) when other treatments have
                not worked well enough.
              </li>
            </ul>
          </section>
        </Container>
      </div>
    </div>
  );
}
