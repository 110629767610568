import React from "react";

import "../css/NotFound.scss";

// Import Components
import PrimaryButton from "../components/shared-ui/Buttons/PrimaryButton";
// import ISI from "../components/shared-ui/ISI/ISI";
import ErrorNavigation from "../components/ErrorNavigation/ErrorNavigation";
import Footer from "../components/shared-ui/Footer/Footer";
import ISIinline from "../components/shared-ui/ISI/ISI-inline";
import BackToTopBtn from "../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIwrapper from "../components/shared-ui/ISI/ISI-wrapper";
import ISI from "../components/shared-ui/ISI/ISI";

function NotFound() {

  return (
    <div className="main_content_wrap notfound">
      <ErrorNavigation />
      <div className='inner-content-wrap'>
        <section className="error-section">
          <div className="flex-error">
            <div className="flex-error-item">
              <h1 className="hide-on-mobile">OOF! LOOKED ALL OVER AND COULDN'T FIND YOU THAT PAGE.</h1>
              <p>
                Get tips and tools sent straight to your inbox.
              </p>
              <div className="btn-wrapper">
                <PrimaryButton title="Join us" href="/home/" />
              </div>
            </div>
            <div className="flex-error-item with-bg">
              <h1 className="extra-large">
                404
              </h1>
              <h1 className="mobile-only">
              OOF! LOOKED ALL OVER AND COULDN'T FIND YOU THAT PAGE.
              </h1>
            </div>
          </div>
        </section>
        <ISIinline/>
      </div>
      <Footer />
      <ISIwrapper>
        <ISI />
      </ISIwrapper>
      <BackToTopBtn/>
    </div>
  );
}

export default NotFound;
