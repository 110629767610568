import React from "react";
import { Button } from "react-bootstrap";

function ModalLinkButton({ title, href, addClass, target, linkout, download, onClick }) {
	return (
		<Button
			variant="primary"
			title={title}
			className={addClass}
			href={href}
			target={target}
			onClick={onClick}
		>
			{title}
		</Button>
	);
}

export default ModalLinkButton;
