import React from "react";
import {
  Button,
  Form,
  Row,
} from "react-bootstrap";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  validateText,
  validateEmail,
  validateCheckbox,
  validateZip,
  validateMobile,
  validate,
} from "../Validation";
import "../../css/SignUpForm.scss";
import { Redirect } from "react-router-dom";
import axios from "axios";

class RecruitmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yourName: "",
      email: "",
      phone: "",
      zip: "",
      checked: false,
      errors: {},
      validated: false,
      isValid: false,
      termsCheckValue: false,
      contactCheckValue: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    //FORM PROGRESS DATAPUSH
    document.querySelectorAll(".form-group input").forEach((el) => {
      el.addEventListener("blur", function() {
        const form_name =
          this.getAttribute("name") === null ||
            this.getAttribute("name") === undefined
            ? document.title
            : this.getAttribute("name");


        if (this.value.length > 0 && !this.classList.contains("completed")) {
          window.dataLayer.push({
            eventCategory: "Form",
            eventAction: "Field Completed",
            eventLabel: `Sign-Up - ${form_name}`,
            event: "e_form_progress",
          });
          this.classList.add("completed");
        } else if (
          !this.classList.contains("completed") &&
          !this.classList.contains("skipped")
        ) {
          window.dataLayer.push({
            eventCategory: "Form",
            eventAction: "Field Skipped",
            eventLabel: `Sign-Up - ${form_name}`,
            event: "e_form_progress",
          });

          this.classList.add("skipped");
        }
      });
    });

    //FORM DATALAYER CHECKBOX
    document.querySelectorAll(".form-check input").forEach((el) => {
      el.addEventListener("change", function() {
        const checkbox_name =
          this.getAttribute("id") === null ||
            this.getAttribute("id") === undefined
            ? document.title
            : this.getAttribute("id");


        if (this.checked === true && !this.classList.contains("completed")) {
          window.dataLayer.push({
            eventCategory: "Form",
            eventAction: "Field Completed",
            eventLabel: `Sign-Up Checkbox - ${checkbox_name}`,
            event: "e_form_progress",
          });
          this.classList.add("completed");
        } else if (this.checked === false) {
          window.dataLayer.push({
            eventCategory: "Form",
            eventAction: "Field Skipped",
            eventLabel: `Sign-Up Checkbox - ${checkbox_name}`,
            event: "e_form_progress",
          });
          this.classList.add("skipped");
        }
      });
    });
  }
  handleChange(event) {
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData, // leave other values unchanged
        [event.target.name]: event.target.value, // update the changed value
      }
    })
  };
  handleSubmit(e) {
    let yourName = this.state.yourName;
    let email = this.state.email;
    let phone = this.state.phone;
    let zip = this.state.zip;
    let termsCheckValue = this.state.termsCheckValue ? "CHECKED" : "UNCHECKED";
    let contactCheckValue = this.state.contactCheckValue ? "CHECKED" : "UNCHECKED";
    let urlParams = window.location.search.substring(1);

    if (validate(e, 'recruitment')) {
      e.preventDefault();
      axios({
        method: "post",
        url: '/service-recruitment.php',
        headers: {
          'content-type': 'application/json'
        },
        data: {
          yourName,
          email,
          phone,
          zip,
          termsCheckValue,
          contactCheckValue,
          urlParams,
        }
      })
        .then(result => {
          if (result.data.success) {
            this.setState({ isValid: true });
          } else {
            document.querySelector('.submit-error').classList.add('show');
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      e.preventDefault();
    }
    // const { formData, errors } = this.state;
    //
  };
  handleExit() {
    document.querySelector("#recruitment-modal").classList.add('fadeOut');
    document.querySelector("#recruitment-modal").classList.remove('fadeIn');
    setTimeout(function() {
      document.querySelector("#recruitment-modal").style.display = "none";
      document.body.classList.remove("no-scroll");
      document.body.classList.remove("modal-open");
    }, 350);
  }
  render() {
    if (!this.state.isValid) {
      return (
        <div className={this.state.validated && !this.state.isValid ? 'form_wrapper hasErrors' : 'form_wrapper'}>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LfcafYZAAAAALR2odRkqSuTIibO0AlSVCwnFj3J"
          >
            <p className="required-error">*Indicates required field</p>
            <Form id="recruitment" noValidate onSubmit={(e) => { this.handleSubmit(e); }}>
              <div className="row inputs d-lg-flex">
                <div className={this.state.yourName ? 'col-lg-6' : 'col-lg-6 name-blank'}>
                  <Form.Group controlId="yourName">
                    <Form.Label><strong>Your name</strong>*</Form.Label>
                    <Form.Control type="text" name="yourName" onChange={(e) => { this.state.validated && (validateText(e.target)); this.setState({ [e.target.name]: e.target.value }) }} required />
                    <Form.Control.Feedback type="invalid">
                      Please enter your name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="email" className="email-group">
                    <div className="email-input"></div>
                    <Form.Label><strong>Email address</strong>*</Form.Label>
                    <Form.Control type="email" placeholder="your@email.com" name="email" onChange={(e) => { this.state.validated && (validateEmail(e.target)); this.setState({ [e.target.name]: e.target.value }) }} required />

                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group controlId="phone">
                    <Form.Label><strong>Phone number</strong> <em>(Optional)</em></Form.Label>
                    <Form.Control type="text" placeholder="(123) 456-7890" name="phone" onClick={(e) => { console.log(this.state) }} onChange={(e) => { this.state.validated && (validateMobile(e.target)); this.setState({ [e.target.name]: e.target.value }) }} />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid phone number.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="zip">
                    <Form.Label><strong>ZIP code</strong>*</Form.Label>
                    <Form.Control type="text" pattern="(d{5}$)|(^\d{5}-\d{4})" name="zip" onChange={(e) => { this.state.validated && (validateZip(e.target)); this.setState({ [e.target.name]: e.target.value }) }} required />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid ZIP code.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <Form.Check
                type="checkbox"
                id="recruitment-agree"
                className="agree-to-terms"
              >
                <Form.Check.Input type="checkbox" onChange={(e) => { this.state.validated && validateCheckbox(e); this.setState({ termsCheckValue: this.state.termsCheckValue ? false : true }) }} required />
                <Form.Check.Label htmlFor="recruitment-agree" >
                  <span>I’m at least 18 years old and I agree to the Sobi, Inc. <a href='https://sobi-northamerica.com/terms-and-conditions' target='_blank' rel="noopener noreferrer" >Terms and Conditions</a> and <a href='https://sobi-northamerica.com/site-privacy-policy' target='_blank' rel="noopener noreferrer" >Privacy&nbsp;Policy</a>.*</span>
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  Please indicate that you accept terms
                  and conditions.
                </Form.Control.Feedback>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="contact-agree"
                className="agree-to-contact"
              >
                <Form.Check.Input type="checkbox" onChange={(e) => { this.state.validated && validateCheckbox(e); this.setState({ contactCheckValue: this.state.contactCheckValue ? false : true }) }} required />
                <Form.Check.Label>
                  <span>I authorize Sobi, Inc., its representatives, and business partners to contact me. I understand that I can opt out at any time.* </span>
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  Please indicate that you accept terms
                  and conditions.
                </Form.Control.Feedback>
              </Form.Check>
              <Row>
                <div className="invalid-feedback submit-error">Something went wrong, please try again later</div>
              </Row>
              <Button variant="primary" className="submit-btn" type="submit" onClick={(e) => { this.setState({ validated: true }); validate(e, 'recruitment') }}>SUBMIT</Button>
            </Form>
          </GoogleReCaptchaProvider>
        </div>
      );
    }
    if (this.state.isValid) {
      return <Redirect to="/recruit-thank-you" />
    }
  }
};

export default RecruitmentForm;
