export function pushDownloadEvent(event) {
  const fileExtension = event.target.href.split(".").pop().toUpperCase();
  const fileName = event.target.href.split("/").pop();

  window.dataLayer.push({
    event: "e_download",
    eventCategory: "Downloads",
    eventAction: `Download - ${fileExtension}`,
    eventLabel: `${fileName}`,
  });
}

export function pushOutboundClick(event) {
  const clickText = event.currentTarget.textContent;
  const clickUrl = event.currentTarget.href;

  window.dataLayer.push({
    event: "e_outbound_nav",
    eventCategory: "Outbound Links",
    eventAction: "Click-Exit Site",
    eventLabel: `${clickText} - ${clickUrl}`,
  });
}
export function pushOutboundClickFacebookFooter(event) {
  const clickText = event.currentTarget.textContent;
  const clickUrl = event.currentTarget.href;


  window.dataLayer.push({
    event: "e_outbound_nav",
    eventCategory: "Outbound Links",
    eventAction: "Click-Exit Site",
    eventLabel: `${clickText} - ${clickUrl} - Footer`,
  });
}
export function pushOutboundClickFacebookHeader(event) {
  const clickText = event.currentTarget.textContent;
  const clickUrl = event.currentTarget.href;

  
  window.dataLayer.push({
    event: "e_outbound_nav",
    eventCategory: "Outbound Links",
    eventAction: "Click-Exit Site",
    eventLabel: `${clickText} - ${clickUrl} - Header`,
  });
}
export function pushOutboundClickModal(event) {
  const clickText = event.target.textContent;
  const clickUrl = event.target.href;
  window.dataLayer.push({
    event: "e_outbound_modal",
    eventCategory: "Outbound Links Modal",
    eventAction: "Click-Exit Site",
    eventLabel: `${clickText} - ${clickUrl}`,
  });
}

export function pushContactClick(event) {
  const clickText = event.target.textContent;

  window.dataLayer.push({
    event: "e_contact",
    eventCategory: "Contact",
    eventAction: `Call`,
    eventLabel: `${clickText}`,
  });
}

export function pushNavHeaderClick(event) {
  const clickText = event.target.textContent;

  window.dataLayer.push({
    event: "e_nav01",
    eventCategory: "Navigation",
    eventAction: `Nav-Header`,
    eventLabel: `${clickText}`,
  });
}

export function pushIndicationClick(event) {
  const clickText = event.target.textContent;

  window.dataLayer.push({
    event: "e_nav02",
    eventCategory: "Navigation",
    eventAction: `Nav-Header`,
    eventLabel: `${clickText}`,
  });
}

export function pushLogoClick(event) {
  window.dataLayer.push({
    event: "e_nav02",
    eventCategory: "Navigation",
    eventAction: `Nav-Header`,
    eventLabel: "LOGO",
  });
}

export function pushCTAButtonClick(event) {
  const clickText = event.target.textContent;
  
  window.dataLayer.push({
    event: "e_cta01",
    eventCategory: "CTA",
    eventAction: `Click-In Site`,
    eventLabel: `${clickText}`,
  });
}

export function pushCTAButtonClickITP(event) {
  window.dataLayer.push({
    event: "e_cta01",
    eventCategory: "CTA",
    eventAction: `Click-In Site`,
    eventLabel: `Manage Immune Thrombocytopenia`,
  });
}
export function pushCTAButtonClickCLD(event) {
  window.dataLayer.push({
    event: "e_cta01",
    eventCategory: "CTA",
    eventAction: `Click-In Site`,
    eventLabel: `Manage Chronic Liver Disease`,
  });
}

export function pushSignUpClick(event) {
  window.dataLayer.push({
    event: "e_form_submit",
    eventCategory: "Form",
    eventAction: "Submit",
    eventLabel: "Sign Up",
    eventSpecialty: "",
  });
}
