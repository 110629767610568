import React from "react";
import { Button } from "react-bootstrap";
import {
  pushCTAButtonClick,
  pushOutboundClick,
  pushDownloadEvent
} from "../../../assets/datalayerFunctions";

function PrimaryButton({ title, href, addClass, target, linkout, download, onClick }) {
  return (
    <Button
      variant="primary"
      title={title}
      className={addClass}
      href={href}
      target={target}
      onClick={(event) => {
        if (download === true) {
          pushDownloadEvent(event);
        } else if (linkout === true) {
          pushOutboundClick(event);
        } else {
          pushCTAButtonClick(event);
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      <span>{title}</span>
    </Button>
  );
}

export default PrimaryButton;
