import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';

import "../css/CookiesBar.scss";

class CookiesBar extends React.Component {
  render() {
    return (
      <div className="cookies_bar show" id='cookie-bar' data-is-cld={window.location.pathname.indexOf('/cld/')}>
        <div className="cookies_bar_bg"></div>
        <Container>
          <CookieConsent
            disableButtonStyles='true'
            disableStyles='true'
            enableDeclineButton='true'
            declineButtonText='&times;'
            buttonText='I agree'
            buttonClasses='btn btn-outline-primary'
            buttonWrapperClasses='button-wrapper'
            containerClasses='d-flex flex-column align-items-center'
            contentClasses='text-center outer-cookie-content'
            onAccept={() => {
              document.getElementById('OuterApp').classList.add('hide-cookie-bar');
              document.getElementById('OuterApp').classList.remove('show-cookie-bar');
              document.querySelectorAll("div .home_hero .flex-wrapper .col .btn-info").forEach(el => {
                el.classList.remove("translate")
              })
            }}
            onDecline={() => {
              document.getElementById('OuterApp').classList.add('hide-cookie-bar');
              document.getElementById('OuterApp').classList.remove('show-cookie-bar');
              document.querySelectorAll("div .home_hero .flex-wrapper .col .btn-info").forEach(el => {
                el.classList.remove("translate")
              })
            }}>
            <Row>
              <Col md={12} className='d-flex space-between align-items-center'>
                <p>
                  This website uses cookies. By continuing to <br className="d-none d-lg-block"></br> use this website, you consent to our use of these cookies. <Link target="_blank" to={{ pathname: "https://sobi-northamerica.com/cookies" }} rel="noopener noreferrer"><strong>Read more about our use of cookies</strong></Link>
                </p>
              </Col>
            </Row>
          </CookieConsent>
        </Container>
        <Link to="/sms-terms" style={{ display: 'none' }}>SMS Terms link</Link>
      </div>
    )
  }
}

export default CookiesBar;
