import React from "react";
import ReactPlayer from 'react-player'

class VideoEmbed extends React.Component {
  state = {
    playing: false,
  }
  handlePlay = () => {
    this.setState({ playing: true })
    document.querySelector("#modal")
  }
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }
  render() {
    const { playing } = this.state
    const desktopImg = this.props.image[0];
    const defaultImg = `/images/${desktopImg}`;
    return (
      <div
        className={
          "video-responsive" +
          (playing ? " playing-video" : "")
        }>
        <picture>
          <img
            alt={this.props.alt}
            src={defaultImg}
            className="video-thumb"
            onClick={this.handlePlay}
          />
        </picture>

        <ReactPlayer
          className="video-player"
          url={'https://www.youtube.com/watch?v=' + this.props.embedId}
          config={{
            youtube: {
              playerVars: { modestbranding: 0 }
            }
          }}
          playing={playing}
          width="100%"
          height="100%"
          controls={true}
        />
        <p>playing: {playing ? 'true' : 'false'}</p>
      </div>
    );
  };
}

export default VideoEmbed;
