import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import "../../../css/ISI-wrapper.scss";

class ISI_wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMinimized: false,
      inlineIsiShowing: false,
      hideMobileIsiPronunciation: false,
    };
    this.handleMinimize = this.handleMinimize.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleMinimize(e) {
    // Keep CLD page scroll the same.
    this.setState({ isMinimized: true });
    if (window.innerWidth > 768) {
      document.querySelector("body").classList.add("isi--minimized");
      document.querySelectorAll("div .home_hero .flex-wrapper .col .btn-info").forEach(el => {
        el.classList.remove("translate")
      });
    }
    else {
      // New ISI behavior actually closes the tray.
      const miniIsi = document.getElementById('mini-isi');
      miniIsi.classList.add('hide-mobile-isi-pronunciation');
      this.setState({ hideMobileIsiPronunciation: true });
      miniIsi.querySelector('.full-isi-for-mobile').scrollTop = 0;
      miniIsi.classList.remove('expanded');
      clearAllBodyScrollLocks();
      // Use this class to push page down.
      document.querySelector('.App').classList.add('hide-mobile-isi-pronunciation');
    }
  }

  handleOpen(e) {
    const miniIsi = document.getElementById('mini-isi');
    // Keep CLD and desktop page scroll the same.
    if (window.innerWidth > 768) {
      const isiTop = document.getElementById("isi").offsetTop;
      const headerHeight = document.querySelector(".header").offsetHeight;
      const top = isiTop - headerHeight;
      //show 10 pixels down the border
      window.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth",
      });
      miniIsi.classList.add('is-minimized');
    }
    else {
      // New ISI behavior actually opens the tray.
      miniIsi.classList.add('expanded');
      disableBodyScroll(document.querySelector('.full-isi-for-mobile'));
    }
  }

  handleScroll(e) {
    let isi = document.getElementById("isi");
    let footer = document.getElementById("footer");
    if (this.isElementVisible(isi) || this.isElementVisible(footer)) {
      this.setState({ inlineIsiShowing: true });
      this.handleMinimize();
    }
    else {
      this.setState({ inlineIsiShowing: false });
    }
  }
  isElementVisible(el) {
    let rect = el.getBoundingClientRect(),
      vWidth = window.innerWidth || document.documentElement.clientWidth,
      vHeight = window.innerHeight - 30 || document.documentElement.clientHeight - 30;

    // Return false if it's not in the viewport
    if (
      rect.right <= 0 ||
      rect.bottom <= 0 ||
      rect.left >= vWidth ||
      rect.top >= vHeight
    ) {
      return false;
    }

    // Return true if any of its four corners are visible
    return true;
  }
  componentDidMount() {
    document.querySelector('.App').classList.remove('hide-mobile-isi-pronunciation');
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    // Clone Full ISI to mini ISI.
    const target = document.querySelector('.isi-inline-itp > .isi-content > .container');
    if (target) {
      const clone = target.cloneNode(true);
      const destination = document.querySelector('.full-isi-for-mobile');
      destination.appendChild(clone);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { children } = this.props;
    const minimizedState = this.state.isMinimized ? "is-minimized" : "";
    const inlineIsiVisible = this.state.inlineIsiShowing
      ? "inline-isi-visible"
      : "";
    const hideMobileIsiPronunciation = this.state.hideMobileIsiPronunciation ? 'hide-mobile-isi-pronunciation' : '';
    const cookieBar = document.querySelector('.cookies_bar');
    if (this.state.inlineIsiShowing) {
      if (cookieBar) {
        cookieBar.classList.add('isi-hidden');
      }
    }
    else {
      if (cookieBar) {
        cookieBar.classList.remove('isi-hidden');
      }
    }
    return (
      <div className={`${minimizedState} ${inlineIsiVisible} ${hideMobileIsiPronunciation}`} id="mini-isi" data-is-cld={window.location.pathname.indexOf('/cld/')}>
        <section className="main-isi isi">
          <div className="isi-bar-wrapper">
            <div className="isi-bar">
              <div className="isi-open-link">
                <Container className="h-100">
                  {" "}
                  <Row className="align-items-center h-100">
                    <Col xs={9} className="bar_title text-left">
                      <span>
                        INDICATION &amp; IMPORTANT SAFETY INFORMATION
                      </span>{" "}
                    </Col>
                    <Col xs={3} className="buttons-wrapper">
                      <div className="expand_icon" id="expand" onClick={this.handleOpen}>
                        <img
                          alt="Open ISI Button"
                          className="add_img"
                          src="/images/open-isi.svg"
                        />
                      </div>
                      <div
                        className={`minimize-icon ${minimizedState}`}
                        onClick={this.handleMinimize}
                      >
                        <img
                          alt="Close ISI Button"
                          className="minimize"
                          src="/images/close-isi.svg"
                        />
                      </div>
                    </Col>{" "}
                  </Row>{" "}
                </Container>
              </div>
            </div>
          </div>
          <div className="scroll-area">{children}</div>
          <div className="full-isi-for-mobile"></div>
        </section>
      </div>
    );
  }
}

export default ISI_wrapper;
