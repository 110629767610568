import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { validateEmail, validateCheckbox, validate } from "../Validation";
import axios from "axios";
import "../../css/UnsubscribeForm.scss";

class UnsubscribeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      checked: false,
      errors: {},
      validated: false,
      isValid: false,
      termsCheckValue: false,
    };
  }
  componentDidMount() {
    document.querySelector('.utility-nav-wrapper li:first-child').style.display = 'none';
    //FORM PROGRESS DATAPUSH
    document.querySelectorAll(".form-group input").forEach((el) => {
    el.addEventListener("blur", function () {
      const form_name =
      this.getAttribute("name") === null ||
      this.getAttribute("name") === undefined
        ? document.title
        : this.getAttribute("name");


      if (this.value.length > 0 && !this.classList.contains("completed")) {
      window.dataLayer.push({
        eventCategory: "Form",
        eventAction: "Field Completed",
        eventLabel: `Sign-Up - ${form_name}`,
        event: "e_form_progress",
      });
      this.classList.add("completed");
      } else if (
      !this.classList.contains("completed") &&
      !this.classList.contains("skipped")
      ) {
      window.dataLayer.push({
        eventCategory: "Form",
        eventAction: "Field Skipped",
        eventLabel: `Sign-Up - ${form_name}`,
        event: "e_form_progress",
      });

      this.classList.add("skipped");
      }
    });
    });

    //FORM DATALAYER CHECKBOX
    document.querySelectorAll(".form-check input").forEach((el) => {
    el.addEventListener("change", function () {
      const checkbox_name =
      this.getAttribute("id") === null ||
      this.getAttribute("id") === undefined
        ? document.title
        : this.getAttribute("id");


      if (this.checked === true && !this.classList.contains("completed")) {
      window.dataLayer.push({
        eventCategory: "Form",
        eventAction: "Field Completed",
        eventLabel: `Sign-Up Checkbox - ${checkbox_name}`,
        event: "e_form_progress",
      });
      this.classList.add("completed");
      } else if (this.checked === false) {
      window.dataLayer.push({
        eventCategory: "Form",
        eventAction: "Field Skipped",
        eventLabel: `Sign-Up Checkbox - ${checkbox_name}`,
        event: "e_form_progress",
      });
      this.classList.add("skipped");
      }
    });
    });
  }
  handleChange(event) {
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData, // leave other values unchanged
        [event.target.name]: event.target.value, // update the changed value
      }
    })
  };
  handleSubmit(e){
    let email = this.state.email;
    let termsCheckValue = this.state.termsCheckValue ? "CHECKED": "UNCHECKED";
    let urlParams = window.location.search.substring(1);

    if(validate(e, 'unsubscribe-form')) {
      e.preventDefault();
      axios({
        method: "post",
        url: '/unsubscribe.php',
        headers: {
          'content-type': 'application/json'
        },
        data: {
          email,
          termsCheckValue,
          urlParams,
        }
      })
      .then(result => {
        if(result.data.success) {
          this.setState({isValid: true});
        } else {
          document.querySelector('.submit-error').classList.add('show');
        }
      })
      .catch(error => {
        console.log(error);
      });
    } else {
      e.preventDefault();
    }
  };
  render() {
    if(!this.state.isValid) {
      return (
        <div className={ this.state.validated && !this.state.isValid ? 'form_wrapper hasErrors' : 'form_wrapper' }>
          <div className="content-set">
            <h1 className="pt-4">Unsubscribe</h1>
            <p>To unsubscribe from receiving communications from Sobi, please make your selection and hit the "submit" button below.</p>
          </div>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LfcafYZAAAAALR2odRkqSuTIibO0AlSVCwnFj3J"
          >
            <Form id="unsubscribe-form" noValidate onSubmit={(e) => { this.handleSubmit(e); }}>
              <Form.Check
                type="checkbox"
                id="unsubscribe-agree"
                className="agree-to-terms"
              >
                <Form.Check.Input type="checkbox" onChange={(e)=>{this.state.validated && validateCheckbox(e); this.setState({termsCheckValue : this.state.termsCheckValue ? false : true })}} required />
                <Form.Check.Label>
                  <span>Click here to unsubscribe from all Doptelet communication programs</span>
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  To unsubscribe you must make a selection.
                </Form.Control.Feedback>
              </Form.Check>

              <Form.Group controlId="email">
                <Form.Control type="email" placeholder="Email address*" name="email" onChange={(e) => {this.state.validated && (validateEmail(e.target)); this.setState({[e.target.name]:e.target.value})}} required />
                <Form.Control.Feedback type="invalid">
                  Please enter valid email address.
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <div className="invalid-feedback submit-error">Something went wrong, please try again later</div>
              </Row>
              <Button variant="primary" className="submit-btn" type="submit" onClick ={(e) => { this.setState({ validated: true }); validate(e, 'unsubscribe-form')}}>SUBMIT</Button>
              <p className="mt-4 pb-5">Important: You will be removed from the communication list within 10 business days. The information you provide in this form will be used in accordance with the Sobi <a href="https://sobi-northamerica.com/site-privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. The information you provide to Sobi may also be used for marketing purposes. Any information you provide regarding the content of any Sobi website, published document, or product will be handled in accordance with Sobi <a href="https://sobi-northamerica.com/terms-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>.</p>
            </Form>
          </GoogleReCaptchaProvider>
        </div>
      );
    }
    if(this.state.isValid) {
      return(
        <div className="text-center pt-5">
          <img
            src="/images/doptelet-platelet-sitting.png"
            alt="Doptelet character sitting down"
          />
          <h1 className="pt-4">Unsubscribe Successful.</h1>
          <p>Thank you. You will no longer receive communications from us.</p>
        </div>
      );
    }
  }
};

export default UnsubscribeForm;
