import React from "react";
import { Container } from "react-bootstrap";

// Import Components
import UnsubscribeForm from "../components/UnsubscribeForm/UnsubscribeForm";
import ErrorNavigation from "../components/ErrorNavigation/ErrorNavigation";

function Unsubscribe() {
  return (
    <div className="main_content_wrap unsubscribe">
      <ErrorNavigation />
      <div className="main_content_wrap">
        <div className="inner-content-wrap">
          <section>
            <Container>
              <UnsubscribeForm />
              <hr/>
              <p className="unsub-footer-text text-center mt-5">
                Doptelet is a registered trademark of AkaRx, Inc.<br/>
                ©2022 Sobi, Inc. All rights reserved. PP-15381 05/22
              </p>
            </Container>
          </section>
        </div>
      </div>
    </div>
  );
}
export default Unsubscribe;
