import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import { NavLink, Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/index";
import SpeakerButton from "../shared-ui/Buttons/SpeakerButton"

import "../../css/ITPNavigation.scss";
import "../../css/PhreesiaModal.scss";

import PrimaryButton from "../shared-ui/Buttons/PrimaryButton";
import history from "../../history";
import {
  pushNavHeaderClick,
  pushDownloadEvent,
  pushOutboundClick,
  pushLogoClick,
  pushCTAButtonClick,
  pushOutboundClickFacebookHeader
} from "../../assets/datalayerFunctions";

function showModal(url) {
  let modal = document.querySelector("#modal");
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100%";
  modal.children[0].children[1].children[3].href = url;
  modal.children[0].children[1].children[3].target = "_blank";
  modal.children[0].children[1].children[3].addEventListener(
    "click",
    function() {
      modal.style.display = "none";
    }
  );
}

function showPhreesiaModal() {
  let modal = document.querySelector("#phreesia-modal");
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100%";
  modal.querySelector('.exitBtn').addEventListener(
    "click",
    function() {
      modal.style.display = "none";
      document.body.classList.remove("no-scroll");
    }
  );
  modal.querySelector('.modal-overlay').addEventListener(
    "click",
    function() {
      modal.style.display = "none";
      document.body.classList.remove("no-scroll");
    }
  );
};


function addModalLinksFacebookHeader(event) {
  pushOutboundClickFacebookHeader(event);
  event.preventDefault();
  let url = event.currentTarget.href;
  let title = event.target.title;
  showModal(url, title);
};

function ITPNavigation(props) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  let location = useLocation();
  let elementToScrollTo;

  const startScrollTo = (event) => {
    const currentPath = window.location.pathname;
    const href = event.target.getAttribute('href');
    const hrefParts = href.split('#');
    if (hrefParts[0] === currentPath) {
      // event.preventDefault();
      const element = document.getElementById(hrefParts[1]);
      scrollToElement(element);
    }
  }

  const scrollToElement = (element) => {
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 1000);
  }

  const elementIsVisibleInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const onScroll = e => {
      // Check if element is in viewport.
      // Stuff for sidebar isi scrolling.
      const isi = document.getElementById('isi');
      const miniIsi = document.getElementById('mini-isi');
      const isiVisible = elementIsVisibleInViewport(isi);
      const sidebarIsiCollapse = 1280;
      const currentScrollTop = (e.target.documentElement.scrollTop < 0) ? 0 : e.target.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        setIsScrollingUp(false);
        // document.body.classList.add('nav-tucked');
        miniIsi.classList.add('isi-minimized')
      }
      else if (currentScrollTop < lastScrollTop) {
        setIsScrollingUp(true);
        // document.body.classList.remove('nav-tucked', 'isi-minimized');
        // miniIsi.classList.remove('isi-minimized')
      }
      lastScrollTop = currentScrollTop;


      if (isiVisible && window.innerWidth <= sidebarIsiCollapse) {
        miniIsi.style.display = 'none';
      }
      else {
        miniIsi.style.display = '';
      }

      // OLD SIDEBAR ISI.
      // Check if element is in viewport.
      // Stuff for sidebar isi scrolling.
      // const isi = document.getElementById('isi');
      // const sidebarIsi = document.getElementById('sidebar-isi');
      // const isiVisible = elementIsVisibleInViewport(isi);
      // const sidebarIsiCollapse = 1280;
      // if (isiVisible) {
      //   sidebarIsi.classList.add('sticky');
      //   isi.classList.add('desktop-full-width');
      //   // Calculate amount of pixels the inline isi is visible to stop sidebar ISI from scrolling.
      //   const isiInfo = isi.getBoundingClientRect();
      //   const headerHeight = 146;
      //   const viewportHeight = window.innerHeight;
      //   const diff = viewportHeight - isiInfo.y;
      //   sidebarIsi.style.top = (headerHeight - diff) + 'px';
      //   if (window.innerWidth <= sidebarIsiCollapse) {
      //     sidebarIsi.style.display = 'none';
      //   }
      //   else {
      //     sidebarIsi.style.display = '';
      //   }
      // }
      // else {
      //   sidebarIsi.classList.remove('sticky');
      //   isi.classList.remove('desktop-full-width');
      //   sidebarIsi.style.top = '';
      //   if (window.innerWidth <= sidebarIsiCollapse) {
      //     sidebarIsi.style.display = '';
      //   }
      // }
      // if (window.innerWidth <= sidebarIsiCollapse) {
      //   sidebarIsi.style.top = '';
      // }
    };
    window.addEventListener('scroll', onScroll);

    // Scroll to hash when page loads.
    let hash = window.location.hash
    if (hash) {
      hash = hash.slice(1);
      const element = document.getElementById(hash);
      scrollToElement(element);
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [elementToScrollTo]);

  return (
    <header className={`header ${isNavOpen ? "navOpen" : ""} ${props.isLandingPage ? 'landing-page' : ''} `}>
      <div className="utility-nav itp-nav">
        <Container>
          <div className="menu_wrap-mobile">
            <ul>
              <li>
                <NavLink
                  to="/home/"
                  activeClassName="is-active"
                  onClick={(event) => {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                    pushNavHeaderClick(event);
                  }}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/what-is-itp/"
                  activeClassName="is-active"
                  onClick={(event) => {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                    pushNavHeaderClick(event);
                  }}
                  title="What is ITP?"
                >
                  What is ITP?
                </NavLink>
                <ul>
                  <li>
                    <NavLink
                      to="/what-is-itp/itp-signs-and-symptoms/"
                      activeClassName="is-active"
                      onClick={(event) => {
                        setIsNavOpen(!isNavOpen);
                        document
                          .querySelector("body")
                          .classList.contains("nav-open")
                          ? document
                            .querySelector("body")
                            .classList.remove("nav-open")
                          : document
                            .querySelector("body")
                            .classList.add("nav-open");
                        pushNavHeaderClick(event);
                      }}
                    >
                      Signs & Symptoms
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/what-is-itp/itp-diagnosis-and-treatment/"
                      activeClassName="is-active"
                      onClick={(event) => {
                        setIsNavOpen(!isNavOpen);
                        document
                          .querySelector("body")
                          .classList.contains("nav-open")
                          ? document
                            .querySelector("body")
                            .classList.remove("nav-open")
                          : document
                            .querySelector("body")
                            .classList.add("nav-open");
                        pushNavHeaderClick(event);
                      }}
                    >
                      Diagnosis &amp; Treatment
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="/what-is-doptelet/"
                  activeClassName="is-active"
                  onClick={(event) => {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                    pushNavHeaderClick(event);
                  }}
                >
                  About Doptelet
                </NavLink>
                <ul>
                  <li>
                    <NavLink
                      to="/what-is-doptelet/why-doptelet/"
                      activeClassName="is-active"
                      onClick={(event) => {
                        setIsNavOpen(!isNavOpen);
                        document
                          .querySelector("body")
                          .classList.contains("nav-open")
                          ? document
                            .querySelector("body")
                            .classList.remove("nav-open")
                          : document
                            .querySelector("body")
                            .classList.add("nav-open");
                        pushNavHeaderClick(event);
                      }}
                    >
                      Considering Doptelet?
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="/managing-itp/"
                  activeClassName="is-active"
                  onClick={(event) => {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                    pushNavHeaderClick(event);
                  }}
                >
                  Managing ITP
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/support-and-resources/"
                  activeClassName="is-active"
                  onClick={(event) => {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                    pushNavHeaderClick(event);
                  }}
                >
                  ITP Support &amp; Resources
                </NavLink>
                <ul>
                  <li>
                    <NavLink
                      to="/support-and-resources/financial-assistance/"
                      activeClassName="is-active"
                      onClick={(event) => {
                        setIsNavOpen(!isNavOpen);
                        document
                          .querySelector("body")
                          .classList.contains("nav-open")
                          ? document
                            .querySelector("body")
                            .classList.remove("nav-open")
                          : document
                            .querySelector("body")
                            .classList.add("nav-open");
                        pushNavHeaderClick(event);
                      }}
                    >
                      Financial Assistance Information
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/support-and-resources/itp-patient-resources/"
                      activeClassName="is-active"
                      onClick={(event) => {
                        setIsNavOpen(!isNavOpen);
                        document
                          .querySelector("body")
                          .classList.contains("nav-open")
                          ? document
                            .querySelector("body")
                            .classList.remove("nav-open")
                          : document
                            .querySelector("body")
                            .classList.add("nav-open");
                        pushNavHeaderClick(event);
                      }}
                    >
                      Chronic ITP Resources
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/support-and-resources/chronic-itp-videos/"
                      activeClassName="is-active"
                      onClick={(event) => {
                        setIsNavOpen(!isNavOpen);
                        document
                          .querySelector("body")
                          .classList.contains("nav-open")
                          ? document
                            .querySelector("body")
                            .classList.remove("nav-open")
                          : document
                            .querySelector("body")
                            .classList.add("nav-open");
                        pushNavHeaderClick(event);
                      }}
                    >
                      ITP Video Resources
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/support-and-resources/living-with-itp/"
                      activeClassName="is-active"
                      onClick={(event) => {
                        setIsNavOpen(!isNavOpen);
                        document
                          .querySelector("body")
                          .classList.contains("nav-open")
                          ? document
                            .querySelector("body")
                            .classList.remove("nav-open")
                          : document
                            .querySelector("body")
                            .classList.add("nav-open");
                        pushNavHeaderClick(event);
                      }}
                    >
                      Get Chronic ITP Tips
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/support-and-resources/share-your-story/"
                      activeClassName="is-active"
                      onClick={(event) => {
                        setIsNavOpen(!isNavOpen);
                        document
                          .querySelector("body")
                          .classList.contains("nav-open")
                          ? document
                            .querySelector("body")
                            .classList.remove("nav-open")
                          : document
                            .querySelector("body")
                            .classList.add("nav-open");
                        pushNavHeaderClick(event);
                      }}
                    >
                      Share Your Story
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="/chronic-itp-faqs/"
                  activeClassName="is-active"
                  onClick={(event) => {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                    pushNavHeaderClick(event);
                  }}
                >
                  FAQ
                </NavLink>
              </li>
              <li className="buttonListItem">
                <PrimaryButton
                  title="FIND A SPECIALIST"
                  onClick={(event) => {
                    showPhreesiaModal();
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                    pushCTAButtonClick(event);
                  }}
                />
              </li>
            </ul>
          </div>
          <ul className="utility-nav-wrapper justify-content-end">
            <li>
              <a href="/cld/">Doptelet for Chronic Liver Disease</a>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "/themes/pdf/prescribing-information.pdf",
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) => {
                  pushDownloadEvent(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                Prescribing Information
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "/themes/pdf/patient-brief-summary.pdf",
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) => {
                  pushDownloadEvent(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                Important Safety Facts
              </Link>
            </li>
            <li className="hcp-link">
              <Link
                to={{ pathname: "https://doptelethcp.com/" }}
                target="_blank"
                rel="noopener noreferrer"

                onClick={(event) => {
                  pushOutboundClick(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                Healthcare Professional Site
              </Link>
            </li>
            <li className="social-link">
              <Link
                to={{ pathname: "https://www.facebook.com/doptelet/" }}
                className="utility-social-link"
                target="_blank"
                rel="noopener noreferrer"
                onClick={addModalLinksFacebookHeader}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <title>Doptelet Blue FaceBook Icon</title>
                  <path id="Facebook" d="M890.6,15.775A12,12,0,1,0,876.734,27.7V19.256h-3.065V15.775h3.065V13.113c0-3.022,1.78-4.7,4.523-4.7a17.122,17.122,0,0,1,2.669.249v2.947h-1.508a1.752,1.752,0,0,0-1.965,1.89v2.263h3.337l-.531,3.482h-2.805v8.444A12.017,12.017,0,0,0,890.6,15.775Z" transform="translate(-866.6 -3.7)" fill="#fff" />
                </svg>
              </Link>
            </li>
            <li className="social-link">
              <Link
                to={{ pathname: "https://www.youtube.com/@dopteletavatrombopag5013" }}
                className="utility-social-link"
                target="_blank"
                rel="noopener noreferrer"
                onClick={addModalLinksFacebookHeader}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="19.6" viewBox="0 0 28 19.6">
                  <title>Doptelet Blue YouTube Icon</title>
                  <g id="YouTube" transform="translate(-369 -803)">
                    <path id="Path_199" data-name="Path 199" d="M27.415,3.06A3.507,3.507,0,0,0,24.939.585C22.756,0,14,0,14,0S5.244,0,3.06.585A3.507,3.507,0,0,0,.585,3.06,36.427,36.427,0,0,0,0,9.8a36.429,36.429,0,0,0,.585,6.739A3.506,3.506,0,0,0,3.06,19.015C5.244,19.6,14,19.6,14,19.6s8.756,0,10.939-.585a3.506,3.506,0,0,0,2.476-2.475A36.43,36.43,0,0,0,28,9.8a36.427,36.427,0,0,0-.585-6.739" transform="translate(369 803)" fill="#fff" />
                    <path id="Path_200" data-name="Path 200" d="M27.213,22.007v-8.4l7.274,4.2Z" transform="translate(352.988 794.994)" fill="#02467b" />
                  </g>
                </svg>
              </Link>
            </li>
            <li className="social-link">
              <Link
                to={{ pathname: "https://www.instagram.com/doptelet_avatrombopag" }}
                className="utility-social-link"
                target="_blank"
                rel="noopener noreferrer"
                onClick={addModalLinksFacebookHeader}
              >
                <svg id="Instagram" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <title>Doptelet Blue Instagram Icon</title>
                  <path id="Path_196" data-name="Path 196" d="M12,2.162c3.2,0,3.584.012,4.849.07a6.665,6.665,0,0,1,2.228.413,3.724,3.724,0,0,1,1.38.9,3.724,3.724,0,0,1,.9,1.38,6.639,6.639,0,0,1,.413,2.228c.058,1.265.07,1.645.07,4.849s-.012,3.584-.07,4.849a6.639,6.639,0,0,1-.413,2.228,3.977,3.977,0,0,1-2.278,2.278,6.639,6.639,0,0,1-2.228.413c-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07a6.639,6.639,0,0,1-2.228-.413,3.724,3.724,0,0,1-1.38-.9,3.724,3.724,0,0,1-.9-1.38,6.665,6.665,0,0,1-.413-2.228c-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849a6.665,6.665,0,0,1,.413-2.228,3.724,3.724,0,0,1,.9-1.38,3.724,3.724,0,0,1,1.38-.9,6.665,6.665,0,0,1,2.228-.413C8.416,2.174,8.8,2.162,12,2.162M12,0C8.741,0,8.332.014,7.052.072A8.785,8.785,0,0,0,4.14.63,5.881,5.881,0,0,0,2.014,2.014,5.881,5.881,0,0,0,.63,4.14,8.785,8.785,0,0,0,.072,7.052C.014,8.332,0,8.741,0,12s.014,3.668.072,4.948A8.785,8.785,0,0,0,.63,19.86a5.881,5.881,0,0,0,1.384,2.126A5.881,5.881,0,0,0,4.14,23.37a8.785,8.785,0,0,0,2.912.558C8.332,23.986,8.741,24,12,24s3.668-.014,4.948-.072a8.785,8.785,0,0,0,2.912-.558,6.133,6.133,0,0,0,3.51-3.51,8.785,8.785,0,0,0,.558-2.912C23.986,15.668,24,15.259,24,12s-.014-3.668-.072-4.948A8.785,8.785,0,0,0,23.37,4.14a5.881,5.881,0,0,0-1.384-2.126A5.881,5.881,0,0,0,19.86.63,8.785,8.785,0,0,0,16.948.072C15.668.014,15.259,0,12,0" fill="#fff" />
                  <path id="Path_197" data-name="Path 197" d="M12,5.838A6.162,6.162,0,1,0,18.162,12,6.162,6.162,0,0,0,12,5.838M12,16a4,4,0,1,1,4-4,4,4,0,0,1-4,4" fill="#fff" />
                  <path id="Path_198" data-name="Path 198" d="M19.846,5.594a1.44,1.44,0,1,1-1.44-1.44,1.44,1.44,0,0,1,1.44,1.44" fill="#fff" />
                </svg>
              </Link>
            </li>
          </ul>
        </Container>
      </div>

      <div className="nav_wrap">
        <Container className="menu-container">
          <Row className="align-items-center justify-content-between">
            <Col xs={2} className="logo-wrapper">
              <Link
                to="/home/"
                onClick={(event) => {
                  pushLogoClick(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                <img
                  alt="Doptelet avatrombopag Tablets Logo"
                  src="/images/doptelet-avatrombopag-tablets-logo.svg"
                />
              </Link>
              <div className="speaker-button">
                <SpeakerButton />
              </div>
            </Col>
            <Col xs={10} className="link-wrapper pr-0">
              <div className="menu_wrap">
                <ul>

                  <li
                    className={
                      location.pathname === "/what-is-itp/"
                        ? "active-submenu submenu-link"
                        : "submenu-link"
                    }
                    title="Treatment"
                  >
                    <NavLink
                      to="/what-is-itp/"
                      activeClassName="is-active"
                      onClick={pushNavHeaderClick}
                      title="What is ITP?"
                    >
                      What is ITP?
                    </NavLink>
                    <FontAwesomeIcon icon={faAngleDown} />
                    <ul className="sub_menu">
                      <li>
                        <NavLink
                          to="/what-is-itp/itp-signs-and-symptoms/"
                          activeClassName="is-active"
                          onClick={pushNavHeaderClick}
                          title="Symptoms of ITP"
                        >
                          Signs & Symptoms
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/what-is-itp/itp-diagnosis-and-treatment/"
                          activeClassName="is-active"
                          onClick={pushNavHeaderClick}
                          title="Diagnosis & Treatment"
                        >
                          Diagnosis & Treatment
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      location.pathname === "/what-is-doptelet/"
                        ? "active-submenu submenu-link"
                        : "submenu-link"
                    }
                    title="About Doptelet"
                  >
                    <NavLink
                      to="/what-is-doptelet/"
                      activeClassName="is-active"
                      onClick={pushNavHeaderClick}
                      title="About Doptelet"
                    >
                      About Doptelet
                    </NavLink>
                    <FontAwesomeIcon icon={faAngleDown} />
                    <ul className="sub_menu">
                      <li>
                        <NavLink
                          to="/what-is-doptelet/why-doptelet/"
                          activeClassName="is-active"
                          onClick={pushNavHeaderClick}
                          title="Clinical Trials"
                        >
                          Considering Doptelet?
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      to="/managing-itp/"
                      activeClassName="is-active"
                      onClick={pushNavHeaderClick}
                      title="Managing ITP"
                    >
                      Managing ITP
                    </NavLink>
                  </li>
                  <li
                    className={
                      location.pathname === "/support-and-resources/"
                        ? "active-submenu submenu-link"
                        : "submenu-link"
                    }
                    title="ITP Support &amp; Resources"
                  >
                    <NavLink
                      to="/support-and-resources/"
                      activeClassName="is-active"
                      onClick={pushNavHeaderClick}
                      title="ITP Support &amp; Resources"
                    >
                      ITP Support &amp; Resources
                    </NavLink>
                    <FontAwesomeIcon icon={faAngleDown} />
                    <ul className="sub_menu">
                      <li>
                        <NavLink
                          to="/support-and-resources/financial-assistance/"
                          activeClassName="is-active"
                          onClick={pushNavHeaderClick}
                          title="Financial Assistance"
                        >
                          Financial Assistance Information
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/support-and-resources/itp-patient-resources/"
                          activeClassName="is-active"
                          onClick={pushNavHeaderClick}
                          title="Chronic ITP Resources"
                        >
                          Chronic ITP Resources
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/support-and-resources/chronic-itp-videos/"
                          activeClassName="is-active"
                          onClick={pushNavHeaderClick}
                          title="ITP Video Resources"
                        >
                          ITP Video Resources
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/support-and-resources/living-with-itp/"
                          activeClassName="is-active"
                          onClick={pushNavHeaderClick}
                          title="Get Chronic ITP Tips"
                        >
                          Get Chronic ITP Tips
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/support-and-resources/share-your-story/"
                          activeClassName="is-active"
                          onClick={pushNavHeaderClick}
                          title="Share Your Story"
                        >
                          Share Your Story
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      location.pathname === "/chronic-itp-faqs/"
                        ? "active-submenu mr-3"
                        : "mr-3"
                    }
                    title="FAQ"
                  >
                    <NavLink
                      to="/chronic-itp-faqs/"
                      activeClassName="is-active"
                      onClick={pushNavHeaderClick}
                      title="FAQ"
                    >
                      FAQ
                    </NavLink>
                  </li>

                  <li className="buttonListItem">
                    <PrimaryButton
                      title="FIND A SPECIALIST"
                      onClick={() => {
                        showPhreesiaModal();
                      }}
                    />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div
            className="menu_btn"
            onClick={() => {
              setIsNavOpen(!isNavOpen);
              document.querySelector("body").classList.contains("nav-open")
                ? document.querySelector("body").classList.remove("nav-open")
                : document.querySelector("body").classList.add("nav-open");
            }}
          >
            <span className="bars">
              <span></span>
              <span></span>
              <span></span>
            </span>
            <div>Menu</div>
            <div>Close</div>
          </div>
          <a target="_blank" className="get-the-guide d-none" href="/pdf/Dr-Discussion-Guide.pdf">Get the Guide</a>
        </Container>
      </div>
      <div
        className="open-nav-overlay"
        onClick={() => {
          setIsNavOpen(!isNavOpen);
          document.querySelector("body").classList.contains("nav-open")
            ? document.querySelector("body").classList.remove("nav-open")
            : document.querySelector("body").classList.add("nav-open");
        }}
      ></div>
      <div id="phreesia-modal" className="modal-modal">
        <div className="modal-container">
          <div className="exitBtn"><img src="/images/close.png" alt="Close Button Icon" /></div>
          <div className="content">
            <div id="medifind-doctor-specialty-widget"></div>
          </div>
        </div>
        <div className="modal-overlay overlay"></div>
      </div>
    </header>
  );
}

export default ITPNavigation;
