import React from "react";
import { Container } from "react-bootstrap";

import "../../../css/ISI.scss";

export default function ISICLD() {
  return (
    <div className="isi-wrapper">
      <div className="expansion_content text-left">
        <Container>
          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>What is DOPTELET<sup>&reg;</sup></strong> (avatrombopag)<strong>?</strong>
            </p>
            <p>DOPTELET is a prescription medicine used to help treat low blood platelet counts in adults with long-lasting (chronic) liver disease (CLD) who are scheduled to have a medical or dental procedure.</p>
            <p><strong><em>Who should not receive DOPTELET?</em></strong></p>
            <p>You should not receive DOPTELET if you have ever had a blood clot, are pregnant, plan to become pregnant or plan to breastfeed. It is not known if</p>
          </section>
        </Container>
      </div>
    </div >
  );
}
