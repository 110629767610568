import React, { useEffect } from "react";
import { helmetJsonLdProp } from "react-schemaorg";
import Helmet from "react-helmet";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import PrimaryButton from "../components/shared-ui/Buttons/PrimaryButton";
import ItpNavigation from "../components/ITPNavigation/ITPNavigation";
import HalfTextHalfImageHero from "../components/HeroHeader/HalfTextHalfImageHero";
import FooterITP from "../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../components/shared-ui/Video/YoutubeEmbed";

// Import Styles
import "../css/DrugLandingPage.scss";

function DrugLandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/avatrombopag-tpo-ra/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
            ],
          }),
        ]}
      >

        <title>DOPTELET® (avatrombopag) &amp; Idiopathic Thrombocytopenic Purpura (ITP)</title>
        <meta
          name="description"
          content="DOPTELET® (avatrombopag) &amp; Idiopathic Thrombocytopenic Purpura (ITP)"
        />
        <link rel="canonical" href="https://doptelet.com/avatrombopag-tpo-ra/" />

      </Helmet>
      <ItpNavigation isLandingPage />
      <div className="inner-content-wrap">
        <HalfTextHalfImageHero
          extraClass="drug-landing-hero"
          flipDisplay
          markup={
            <>
              <h1><strong>When it comes to chronic ITP treatments, similar isn’t the same.</strong></h1>
              <p>There’s only one pill that can raise your platelets to 50,000 per microliter in as little as 8 days&mdash;without placing any limits on when you can eat certain foods. It's called Doptelet.</p>
            </>
          }
          image={['drug-landing-page-hero_@2X.png', 'drug-landing-page-hero_@2X.png']}
          imageMobile={['home-hero.jpg', 'home-hero@2x.jpg']}
          imageAlt="Four Doptelet Platelet Characters Having Picnic in Park"
        />

        <section>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex flex-lg-row flex-column align-items-center mb-1 mt-5">
                  <picture className="col-12 col-lg-5 text-center">
                    <source
                      media="(min-width: 992px)"
                      srcSet="/images/5.0_hero.png 1x, /images/5.0_hero_@2X.png 2x"
                      type="image/png" />
                    <source
                      media="(max-width: 991px)"
                      srcSet="/images/5.0_hero.png 1x, /images/5.0_hero_@2X.png 2x"
                      type="image/png" />
                    <img src="/images/5.0_hero.png" className="img-fluid" alt="Doptelet Platelet Character Eating Two Ice Cream Cones" />
                  </picture>
                  <div className="col-12 col-lg-7">
                    <h3 className="mb-4"><strong>A chronic ITP pill that stands out.</strong></h3>
                    <p>With Doptelet, you don’t need to worry about when you eat certain foods because of treatment. You can have dairy before bed without setting the alarm. Or you can enjoy a bowl of cereal at breakfast without waking up at 5 AM. It’s totally up to you!</p>
                    <p>Plus, Doptelet isn’t an injection–it’s a pill with no significant risk for liver damage or developing cataracts. That means fewer visits to your doctor’s office for liver testing, eye exams, or treatment injections.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="cloud-blue-bg video-section">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex flex-lg-row flex-column align-items-center mb-1">
                  <div className="col-12 col-lg-5">
                    <div className="video-wrapper">
                      <YoutubeEmbed
                        embedId="_sHszTR2J8c"
                        image={["dopteller_diaries_support_resources.jpg", "dopteller_diaries_support_resources.jpg"]}
                        imageMobile={["dopteller_diaries_support_resources.jpg", "dopteller_diaries_support_resources.jpg"]}
                        alt="Play Video: Dopteller Diaries: Support Resources for ITP"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-7">
                    <h3 className="mb-4"><strong>Get the real scoop on Doptelet from a real patient.</strong></h3>
                    <p>Tammy has been living with chronic ITP for 15 years and was looking for a treatment that would lift platelets fast and keep them stable. When it came to starting on Doptelet, she only had one regret—that she didn’t start sooner. Watch Tammy’s story.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex flex-lg-row flex-column align-items-center mb-1 mt-5">
                  <div className="col-12 col-lg-5 text-center">
                    <img src="/images/Platey_Selfie.png" alt="Doptelet Platelet Character Taking Selfie" className="img-fluid" />
                    <p className="d-none d-md-block">
                      <PrimaryButton
                        title="ENROLL HERE"
                        addClass="m-auto"
                        href="/support-and-resources/financial-assistance/"
                      />
                    </p>
                  </div>
                  <div className="col-12 col-lg-7">
                    <h3 className="mb-4"><strong>Doptelet Connect: your resource for getting started on Doptelet.</strong></h3>
                    <p>Dealing with insurance companies and figuring out how to pay for your treatment can be stressful. Don’t worry. Doptelet Connect can partner with you and your doctor to help you with:</p>
                    <ul className="mb-4">
                      <li>Calling your insurance company to investigate coverage for you</li>
                      <li>Coordinating the delivery of Doptelet to your doorstep</li>
                      <li>Connecting eligible patients to a savings program, including a Free Trial Offer*</li>
                    </ul>
                    <p className="d-block d-md-none">
                      <PrimaryButton
                        title="ENROLL HERE"
                        addClass="m-auto"
                        href="/support-and-resources/financial-assistance/"
                      />
                    </p>
                    <p className="footnote">
                      <span>*</span>In order to participate in the Doptelet Free Trial Offer, a patient must be new to Doptelet therapy and be aged ≥18 years. The patient must be prescribed Doptelet for an approved on-label indication (ITP only) and be under the care of a licensed healthcare provider who is authorized to prescribe medicine in the United States or US Territories and the patient must reside in the United States or US Territories. Additionally, a patient can only participate in the Doptelet Free Trial Offer once. The offer is void where prohibited by law. Certain rules and restrictions apply. Sobi reserves the right to revoke, rescind, or amend this offer without notice.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="dark-blue-bg get-discussion-guide">
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex flex-lg-row flex-column align-items-center mb-1 mt-5">
                  <picture className="col-12 col-lg-5 text-center">
                    <source
                      srcSet="/images/platey_fountain.png 1x, /images/platey_fountain_@2X.png 2x"
                      type="image/png" />
                    <img className="img-fluid" src="/images/platey_fountain.png" alt="" />
                  </picture>
                  <div className="col-12 col-lg-7">
                    <h3><strong>Now that you’re in the know—take the big step forward.</strong></h3>
                    <p className="pb-3">Get the ball rolling on a new chapter in your treatment journey. Prepare for your next appointment with our discussion guide and talk to your doctor about starting on Doptelet.</p>
                    <p className="pb-0">
                      <PrimaryButton
                        title="GET THE DISCUSSION GUIDE"
                        addClass="m-auto"
                        target="_blank"
                        href="/pdf/Dr-Discussion-Guide.pdf"
                      />
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex flex-lg-row flex-column align-items-center mb-1 mt-5">
                  <div className="col-12 col-lg-4 text-center">
                    <img src="/images/Platey_Selfie.png" alt="Doptelet Platelet Character Taking Selfie" className="img-fluid" />
                  </div>
                  <div className="col-12 col-lg-8">
                    <h3><strong>Interested in learning more about Doptelet? Sign up for updates today.</strong></h3>
                    <p className="pb-0 text-sm-left text-md-center">
                      <PrimaryButton
                        title="SIGN ME UP"
                        addClass="m-auto"
                        href="/support-and-resources/living-with-itp/"
                      />
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <ISIITPinline />
      </div>
      <FooterITP pp="PP-21841 03/24" copyyear="2024" />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default DrugLandingPage;
