import React from "react";
import "../../../css/Modal.scss";

class ExitModal extends React.Component {
  componentDidMount() {
    let exitBtn = document.querySelectorAll(".exitBtn, .modal-modal > .overlay");
    exitBtn.forEach(function(exitLink) {
      exitLink.addEventListener("click", function() {
        document.body.classList.remove("no-scroll", "modal-open");
        document.querySelector("#modal").classList.add("fadeOut");
        document.querySelector("#modal").classList.remove("fadeIn");
        setTimeout(function() {
          document.querySelector("#modal").style.display = "none";
        }, 350);
      });
    });
  }

  render(url) {
    return (
      <div id="modal" className="modal-modal">
        <div className="modal-container">
          <div className="exitBtn">
            <img src="/images/close.png" alt="Close Button Icon" />{" "}
          </div>
          <div className="content">
            <img
              src="/images/doptelet-avatrombopag-tablets-logo.svg"
              alt="Doptelet avatrombopag Tablets Logo"
            />
            <p className="heading-2">
              You are about to&nbsp;leave DOPTELET.com/itp and go to an external&nbsp;page.
            </p>
            <p className="mb-0">
              Please note that Sobi, Inc. is not responsible for the content of the website you are <br className="modal-br" /> about to visit.
            </p>
            <a
              title={url}
              href={url}
              rel="noopener noreferrer"
              target="_blank"
              className="linkButton btn btn-primary"
              onClick={() => {
                document.body.classList.remove("no-scroll");
                document.body.classList.remove("modal-open");
              }}
            >
              CONTINUE
            </a>
            <p className="exitBtn exitLink">Go back</p>
          </div>
        </div>
        <div className="overlay" onClick={this.handleExit}></div>
      </div>
    );
  }
}

export default ExitModal;
