import React from 'react';
import "../../../css/Modal.scss";
import { pushOutboundClick } from "../../../assets/datalayerFunctions"

class TermsModal extends React.Component {
  handleExit() {
    document.querySelector("#terms-modal").classList.add('fadeOut');
    document.querySelector("#terms-modal").classList.remove('fadeIn');
    setTimeout(function() {
      document.querySelector("#terms-modal").style.display = "none";
      document.body.classList.remove("no-scroll");
      document.body.classList.remove("modal-open");
    }, 350);
  }
  handleClick(event) {
    document.querySelector("#terms-modal").classList.add('fadeOut');
    document.querySelector("#terms-modal").classList.remove('fadeIn');
    setTimeout(function() {
      document.querySelector("#terms-modal").style.display = "none";
      document.body.classList.remove("no-scroll");
      document.body.classList.remove("modal-open");
    }, 350);
    pushOutboundClick(event);
  }
  componentDidMount() {
  }

  render(scrollTo) {
    return (
      <div id="terms-modal" className='modal-modal'>
        <div className='modal-container'>
          <div className='exitBtn' onClick={this.handleExit} role="button"><img src="/images/icon-close-blue@2x.png" alt='Close Modal' /> </div>
          <div className='content'>
            <p className="heading-2" id="discover">Discover the Doptelet Copay<br className="d-none d-lg-block"></br> Assistance Program</p>
            <h3>Eligible commercially insured patients may qualify for the Doptelet Copay Assistance Program</h3>

            <h3>What's the program?</h3>
            <ul>
              <li>Eligible patients may pay as little as $0 for each Doptelet prescription</li>
              <li>Annual maximum benefit up to $15,000</li>
            </ul>
            <h3>Eligible patients must:</h3>
            <ul>
              <li>Have commercial insurance that covers Doptelet</li>
              <li>Not be enrolled in any state or federal healthcare program such as Medicare, Medicaid, Medigap, Veterans Affairs (VA), Department of Defense (DOD), or TRICARE</li>
              <li>Be 18 years of age or older</li>
              <li>Be a resident in the United States or a US Territory</li>
            </ul>
            <h3>How to enroll:</h3>
            <ul>
              <li>Approved pharmacies, healthcare professionals, and Doptelet Connect can enroll patients</li>
              <li>Patients can enroll themselves as well as their caregivers</li>
              <li>Please call <a href="tel:+18333682663"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                <strong>1-833-368-2663</strong></a> <span className="break-on-mobile-only">Monday–Friday 8<span className="small-caps">am</span>–8<span className="small-caps">pm</span> ET for more information</span></li>
            </ul>
            <p className="heading-2" id="terms">Terms and Conditions</p>
            <ul>
              <li>Patients pay as little as $0 per prescription. Copay Assistance Program has an annual calendar cap of $15,000</li>
              <li>The Copay Assistance Program is void where prohibited by law, taxes, or restricted</li>
              <li>This offer is non-transferable, no substitutions are permissible, and this offer cannot be combined with any other rebate/coupon, free trial, or similar offer for the specified prescription</li>
              <li>Sobi, Inc. reserves the right to rescind, revoke, or amend this offer at any time without notice</li>
              <li>The Copay Assistance Program for Doptelet is not insurance and is not intended to substitute for insurance</li>
              <li>Patients, pharmacists, and healthcare providers must not seek reimbursement from health insurance or any third party for any part of the benefit received by the patient through this Copay Assistance Program. Patients must not seek reimbursement from any health savings, flexible spending, or other healthcare reimbursement accounts for the amount of assistance received from the Copay Assistance Program</li>
              <li>Certain information pertaining to your use of the Copay Assistance Program will be shared with Sobi, Inc., the sponsor of the Copay Assistance Program. The information disclosed will include the date the prescription is filled, the number of pills or product dispensed by the pharmacists, and the amount of your copay that will be paid for by using this Copay Assistance Program. For more information, please see the Sobi Privacy Policy at <a href="https://sobi-northamerica.com/privacy-policy" className="text-underline" target="_blank" rel="noopener noreferrer">https://sobi-northamerica.com/privacy-policy</a></li>
              <li>Acceptance in this Copay Assistance Program is not conditioned on any past, present, or future purchase, including additional doses</li>
              <li>Enrollment for the Copay Assistance Program is valid through the calendar year</li>
              <li>Sobi reserves the right to change program terms at any time</li>
            </ul>
          </div>
        </div>
        <div className="overlay" onClick={this.handleExit}></div>
      </div>
    );
  }
}

export default TermsModal;
