import React from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";

import "../../css/PatientResources/Resource.scss";

function Resource(props) {
  return (
    <div className="resource-section--resource">
      <Container>
        <Row>
          <Col md={{span: 10, offset: 1}}>
            {props.children}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

function Content(props) {
  return (
    <div className="d-flex align-items-center">
      <img src={props.image} alt={props.imageAlt} className="mr-4 d-none d-md-block resource-image resource-image--desktop" />
      <div>
        <p className="d-flex align-items-center">
          <img src={props.image} alt={props.imageAlt} className="mr-4 d-md-none d-sm-inline-block resource-image resource-image--mobile" />
          <strong>{props.title}</strong>
        </p>
        <p>{props.content}</p>
        {props.children}
      </div>
    </div>
  )
}

function Link(props) {
  return (
    <p>
      <a href={`${props.link ? props.link : '#'}`} className="resource-section--link download-link" target="_blank" rel="noopener noreferrer">
        {props.children}
        <span><img src="/images/icon_download.svg" alt="Download icon" aria-hidden="true"/></span>
      </a>
    </p>
  )
}

function AppLink(props) {
  return (
    <a href={props.link ? props.link : '#'} className="app-link" target="_blank" rel="noopener noreferrer">
      <img src={props.image} alt={props.imageAlt} />
    </a>
  )
}

Resource.AppLink = AppLink;
Resource.Content = Content;
Resource.Link = Link;
export default Resource;
