import React from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";

import "../../../css/AccordionSection.scss";

export default function ITPAccordionSection(props) {
  return (
    <div className="accordions">
      <Container>
        <Row>
          <Col md={{ span: 12 }}>
            <h2>{props.name}</h2>
            {props.children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
