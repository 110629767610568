import React from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";

import "../../css/HeroHeader.scss";

function HalfTextHalfImageHero(props) {
  const desktopImg = props.image[0];
  const defaultImg = `/images/${desktopImg}`;
  const extraClasses = (props.extraClass) ? props.extraClass : '';
  return (
    <div className={`hero_halves ${extraClasses}`}>
      <Container>
        <Row className={`no-gutters ${props.flipDisplay ? 'flex-lg-row flex-column-reverse' : ''}`}>
          <Col md={12} lg={6}>
            {props.markup}
          </Col>
          <Col md={12} lg={{ span: 5, offset: 1 }} className="hero-bg-img">
            <picture>
              <img src={defaultImg} alt={props.imageAlt} />
            </picture>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HalfTextHalfImageHero;
