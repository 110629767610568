import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { helmetJsonLdProp } from "react-schemaorg";

import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import HalfTextHalfImageHero from "../../components/HeroHeader/HalfTextHalfImageHero";
import Accordion from "../../components/shared-ui/Accordion/Accordion";
import ITPAccordionSection from "../../components/shared-ui/Accordion/ITPAccordionSection";

// Import Styles
import "../../css/FAQ.scss";

function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "DOPTELET® (avatrombopag) & Idiopathic Thrombocytopenic Purpura (ITP) FAQs",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "MedicalTherapy",
                seriousAdverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "DOPTELET may cause serious side effects, including blood clots. People with chronic immune thrombocytopenia and people with certain blood clotting conditions may have an increased risk of developing blood clots. Tell your healthcare provider right away if you have signs and symptoms of a blood clot, including:Swelling, pain, or tenderness in your legs, Shortness of breath, Chest pain, Fast heartbeat, and Stomach (abdominal) pain or tenderness.",
                },
              },
              {
                "@type": "MedicalTherapy",
                adverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "'The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, and purple or red spots on your skin.",
                },
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://doptelet.com/chronic-itp-faqs/",
                      "Name": "ITP"
                    }
                  }
                ]
              },
            ],
          }),
        ]}
      >
        <title>FAQs: Chronic ITP Patient Information | DOPTELET® (avatrombopag)
        </title>
        <meta
          name="description"
          content="Find answers to common questions about Chronic ITP and DOPTELET (avatrombopag), including information about treatment, dosing, financial support & more."
        />
        <link rel="canonical" href="https://doptelet.com/chronic-itp-faqs/" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap">
        <HalfTextHalfImageHero
          markup={
            <>
              <h1>Frequently asked questions</h1>
            </>
          }
          image={['faq-header.jpg']}
          imageAlt="Doptelet Platelet Character Eating Two Ice Cream Cones"
          extraClass="faq-banner"
        />

        <ITPAccordionSection name="Doptelet Dosing">
          <Accordion
            title="What if I miss a dose of Doptelet?"
            markup={
              <>
                <p>If you miss your dose of Doptelet, take it as soon as you remember. Don’t take 2 doses at once to make up for missing a dose. You should take your next dose at your usual time.</p>
              </>
            }
          />
          <Accordion
            title="What if I take too much Doptelet?"
            markup={
              <>
                <p>If you think you’ve taken more Doptelet than you were prescribed, call your doctor or go to the nearest emergency room right away.</p>
              </>
            }
          />
          <Accordion
            title="How long will I be
            taking Doptelet?"
            markup={
              <>
                <p>Everyone’s treatment goals are different, so it’s important to keep taking Doptelet exactly as your doctor prescribed, based on the treatment plan you agreed on together. If you have questions about how long you’ll be on Doptelet,{" "}
                  <Link to="/managing-itp/">talk to your doctor or care team</Link>—they’re there to help.</p>
              </>
            }
          />
        </ITPAccordionSection>

        <ITPAccordionSection name="Financial Support">
          <Accordion
            title="How much does Doptelet cost?"
            markup={
              <>
                <p>The cost of Doptelet may vary depending on your insurance. Eligible patients who have commercial insurance may pay as little as $0 with the Doptelet Copay Assistance Program.{" "}
                  <Link to="/support-and-resources/financial-assistance/">Find out if you’re eligible</Link>.</p>
                <p>Doptelet Connect also has dedicated Case Managers who can help research your individual coverage, coordinate with a pharmacy to fill your prescription, and connect you with copay assistance or free product, if needed. <Link to="/support-and-resources/financial-assistance/#doptelet-connect">Learn more</Link>.</p>
              </>
            }
          />
        </ITPAccordionSection>

        <ITPAccordionSection name="General Doptelet Info">
          <Accordion
            title="Was Doptelet studied in clinical trials?"
            markup={
              <>
                <p>Yes. 49 patients living with chronic ITP were studied in a 6-month clinical trial for Doptelet. Some were taking Doptelet, some weren’t. On Day 8 of the trial, 21 out of the 32 patients taking Doptelet reached at least 50,000 platelets per microliter or more. Those not on Doptelet didn’t reach 50,000 platelets per microliter. Beyond that, the patients taking Doptelet were able to keep their platelet counts at 50,000 platelets per microliter or higher for an average of 12 total weeks vs 0 weeks for those not on Doptelet.</p>
              </>
            }
          />
          <Accordion
            title="Is Doptelet a steroid?"
            markup={
              <>
                <p>No, Doptelet isn’t a steroid. It’s a thrombopoietin receptor agonist (TPO-RA) that can help lift your platelet count.</p>
              </>
            }
          />
          <Accordion
            title="What kind of food can I eat with Doptelet?"
            markup={
              <>
                <p>With Doptelet, you have full food freedom, meaning you can eat any food you want, whenever you want. This includes calcium- and magnesium-rich foods.</p>
              </>
            }
          />
          <Accordion
            title="How is Doptelet different than other treatments?"
            markup={
              <>
                <p>Doptelet is the only oral thrombopoietin receptor agonist (TPO-RA) that helps lift your platelet count on your ideal schedule with no food-type restrictions, additional liver monitoring, or injections.</p>
                <p>Friendly reminder: Food’s a must when taking your dose of Doptelet, but which kind is up to you. When you start on Doptelet, your doctor will check your platelet count with a weekly blood test. Once you’ve reached your treatment goal, they’ll check it monthly.</p>
                <p><Link to="/what-is-doptelet/">Discover why Doptelet can be the right ITP treatment option for you</Link>.</p>
              </>
            }
          />
          <Accordion
            title="What are the side effects of Doptelet?"
            markup={
              <>
                <p>The most common side effects of Doptelet are headache, tiredness, bruising, nosebleed, upper respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin (petechiae), and runny nose. Doptelet is a thrombopoietin receptor agonist (TPO-RA) and TPO-RAs have been associated with blood clots. Tell your doctor right away if you have symptoms of a blood clot, including: swelling, pain, or tenderness in your legs, shortness of breath, chest pain, fast heartbeat, and stomach (abdominal) pain or tenderness.</p>
              </>
            }
          />
          <Accordion
            title="What do other patients say about Doptelet?"
            markup={
              <>
                <p>Meet Tammy, our Dopteller (aka patient ambassador). You can watch her talk about life after diagnosis, reaching her goals, why Doptelet works for her, and more {" "}
                  <Link to="/support-and-resources/chronic-itp-videos/">here</Link>.</p>
              </>
            }
          />
        </ITPAccordionSection>

        <ITPAccordionSection name="Treatment Goals">
          <Accordion
            title="What should my treatment goal be?"
            markup={
              <>
                <p>While everyone’s treatment goal is unique (based on their age, medical history, activity level, etc), for most people, keeping a count of 50,000 platelets per microliter or more can help reduce risk.</p>
                <p>Talk to your doctor to figure out what your treatment goals should be.</p>
              </>
            }
          />
          <Accordion
            title="Will Doptelet make my platelet count normal?"
            markup={
              <>
                <p>It’s important to know and remember that the goal of treatment may not be to normalize your platelet counts. Everyone’s platelet count goal is unique, so it’s up to you and your doctor to set and reach your goals together.</p>
              </>
            }
          />
          <Accordion
            title="What is a normal platelet count?"
            markup={
              <>
                <p>A normal platelet count in adults ranges from 150,000 to 450,000 platelets per microliter of blood, but for most people keeping a count of 50,000 platelets per microliter or more can help reduce risk.</p>
              </>
            }
          />
        </ITPAccordionSection>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div >
  );
}

export default FAQ;
