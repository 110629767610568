import React from "react";
import { Container } from "react-bootstrap/esm/index";

import "../../css/HeroHeader.scss";

function TextOnlyHeroHeader(props) {
  const extraClasses = (props.extraClass) ? props.extraClass : '';
  return (
    <div className={`hero_text_only ${extraClasses}`}>
      <Container className="p-0">
        {props.markup}
      </Container>
    </div>
  );
}

export default TextOnlyHeroHeader;
