import React from "react";
import { Container } from "react-bootstrap";

import "../../../css/Footer.scss";
import { Link } from "react-router-dom";

import { pushOutboundClick, pushOutboundClickFacebookFooter } from "../../../assets/datalayerFunctions";

function showModal(url) {
  let modal = document.querySelector("#modal");
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100%";
  modal.children[0].children[1].children[3].href = url;
  modal.children[0].children[1].children[3].target = "_blank";
  modal.children[0].children[1].children[3].addEventListener(
    "click",
    function() {
      modal.style.display = "none";
    }
  );
}

function addModalLinksFacebookFooter(event) {
  pushOutboundClickFacebookFooter(event);
  event.preventDefault();
  let url = event.currentTarget.href;
  let title = event.target.title;
  showModal(url, title);
};

/**
 * Accepts prop "pp" to allow for override per instance.
 *
 * "pp" = Legal review #
 */
class FooterITP extends React.Component {
  render() {
    return (
      <footer className="main-footer" id="footer">
        <div className="footer-container">
          <Container>
            <div className="footer-content">
              <div className="footer_logo">
                <Link
                  to={{ pathname: "https://sobi-northamerica.com/" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg id="SOBI_logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="161" height="60.375" viewBox="0 0 161 60.375">
                    <title>Sobi Rare Strength Logo</title>
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_834" data-name="Rectangle 834" width="161" height="60.375" fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Group_14846" data-name="Group 14846" transform="translate(0 0)" clipPath="url(#clip-path)">
                      <path id="Path_1809" data-name="Path 1809" d="M20.125,2.686A20.126,20.126,0,1,0,40.249,22.811,20.126,20.126,0,0,0,20.125,2.686m-6.914,34.05a3.975,3.975,0,1,1,3.972-3.974,3.972,3.972,0,0,1-3.972,3.974m0-9.993a3.973,3.973,0,1,1,3.972-3.973,3.972,3.972,0,0,1-3.972,3.973m0-9.944a3.972,3.972,0,1,1,3.972-3.972A3.97,3.97,0,0,1,13.211,16.8" transform="translate(0 1.128)" fill="#fff" />
                      <path id="Path_1810" data-name="Path 1810" d="M45.672,39.98c-5.624,0-9.927-2.178-12.789-6.472a.214.214,0,0,1,.058-.3l3.993-2.731a.2.2,0,0,1,.163-.034.207.207,0,0,1,.138.1,9.72,9.72,0,0,0,8.437,4.805c3.729,0,7.492-1.622,7.492-5.249,0-2.965-2.323-4.129-6.072-5.048L42.61,24c-5.609-1.4-8.572-4.456-8.572-8.826,0-6.972,6.2-10.093,12.333-10.093,5.268,0,9.215,2.133,11.119,6a.213.213,0,0,1-.074.274l-4.133,2.733a.212.212,0,0,1-.305-.08,7.456,7.456,0,0,0-6.887-4.3c-3.277,0-6.583,1.45-6.583,4.689,0,2.988,2.114,4.02,6.279,4.975l4.7,1.122c4.95,1.142,8.15,4.745,8.15,9.176,0,7.6-6.983,10.3-12.964,10.3" transform="translate(13.793 2.135)" fill="#fff" />
                      <path id="Path_1811" data-name="Path 1811" d="M70.822,39.98c-10.191,0-17.587-7.338-17.587-17.448s7.4-17.447,17.587-17.447c10.232,0,17.66,7.337,17.66,17.447S81.054,39.98,70.822,39.98m0-30c-6.989,0-11.869,5.163-11.869,12.554S63.835,35.09,70.824,35.09c7.029,0,11.939-5.163,11.939-12.558S77.852,9.978,70.824,9.978" transform="translate(22.355 2.135)" fill="#fff" />
                      <path id="Path_1812" data-name="Path 1812" d="M99.261,42.115c-3.849,0-9.411-1.592-12.676-6.045v4.988a.215.215,0,0,1-.214.216H81.533a.214.214,0,0,1-.213-.216V.214A.214.214,0,0,1,81.533,0h4.838a.215.215,0,0,1,.214.214V13.264c3.264-4.453,8.826-6.045,12.676-6.045,10.232,0,17.658,7.337,17.658,17.448s-7.426,17.448-17.658,17.448m.5-29.966A12.275,12.275,0,0,0,87.174,24.667,12.275,12.275,0,0,0,99.759,37.185c7.007,0,11.9-5.147,11.9-12.518s-4.893-12.518-11.9-12.518" transform="translate(34.149 0)" fill="#fff" />
                      <path id="Path_1813" data-name="Path 1813" d="M114.729,38.891h-4.838a.215.215,0,0,1-.214-.214V5.89a.214.214,0,0,1,.214-.213h4.838a.212.212,0,0,1,.213.213V38.676a.213.213,0,0,1-.213.214" transform="translate(46.056 2.384)" fill="#fff" />
                      <path id="Path_1814" data-name="Path 1814" d="M57.579,36.536a.267.267,0,0,0-.3-.3h-.588v-.518h.669c.559,0,.791.233.791.75v.477c0,.315-.027.561-.027.561h.027a2.335,2.335,0,0,1,2.155-1.842,2.134,2.134,0,0,1,.395.041v.572a2.235,2.235,0,0,0-.312-.027c-1.065,0-1.732.9-2.033,1.91a4.861,4.861,0,0,0-.2,1.43v2.946h-.572Z" transform="translate(23.807 14.976)" fill="#fff" />
                      <path id="Path_1815" data-name="Path 1815" d="M64.041,38.41h.452v-.1c0-1.623-.559-2.2-1.978-2.2-.355,0-1.512.1-1.512.667v.464h-.575v-.628c0-.805,1.542-1.037,2.1-1.037,2.086,0,2.536,1.133,2.536,2.577v3.587a.268.268,0,0,0,.3.3h.585v.517h-.667c-.559,0-.791-.219-.791-.831,0-.369.027-.615.027-.615h-.027a2.549,2.549,0,0,1-2.36,1.61,2.093,2.093,0,0,1-2.317-1.978c0-2.317,3.013-2.346,4.226-2.346M62.159,42.2c1.487,0,2.333-1.541,2.333-2.891v-.368h-.437c-1.159,0-3.641,0-3.641,1.772A1.574,1.574,0,0,0,62.159,42.2" transform="translate(25.118 14.944)" fill="#fff" />
                      <path id="Path_1816" data-name="Path 1816" d="M65.464,36.536a.267.267,0,0,0-.3-.3h-.586v-.518h.669c.558,0,.789.233.789.75v.477c0,.315-.027.561-.027.561h.027a2.335,2.335,0,0,1,2.155-1.842,2.135,2.135,0,0,1,.395.041v.572a2.235,2.235,0,0,0-.312-.027c-1.065,0-1.732.9-2.032,1.91a4.817,4.817,0,0,0-.206,1.43v2.946h-.572Z" transform="translate(27.118 14.976)" fill="#fff" />
                      <path id="Path_1817" data-name="Path 1817" d="M71.157,35.587c1.856,0,2.783,1.46,2.783,3.11a3.552,3.552,0,0,1-.028.368H68.443a2.88,2.88,0,0,0,2.918,3.108,3.091,3.091,0,0,0,2.114-.858l.312.477a3.708,3.708,0,0,1-2.427.941,3.427,3.427,0,0,1-3.517-3.56,3.314,3.314,0,0,1,3.313-3.587m2.182,2.945a2.2,2.2,0,0,0-2.2-2.412,2.652,2.652,0,0,0-2.66,2.412Z" transform="translate(28.49 14.944)" fill="#fff" />
                      <path id="Path_1818" data-name="Path 1818" d="M76.2,40.4a3.11,3.11,0,0,0,2.073,1.024c.532,0,.941-.22.941-.683,0-.981-3.669-.967-3.669-3.218,0-1.392,1.255-2.032,2.7-2.032.943,0,2.442.314,2.442,1.445v.723H79.162v-.341c0-.327-.491-.491-.872-.491-.615,0-1.037.219-1.037.628,0,1.091,3.7.873,3.7,3.191,0,1.309-1.16,2.128-2.674,2.128a3.811,3.811,0,0,1-2.891-1.241Z" transform="translate(31.656 14.904)" fill="#fff" />
                      <path id="Path_1819" data-name="Path 1819" d="M80.722,37.544h-.9V36.167h.94v-1.9h1.693v1.9h1.582v1.377H82.455v2.81a1.234,1.234,0,0,0,1.376,1.308,1.688,1.688,0,0,0,.314-.026v1.512a3.046,3.046,0,0,1-.531.043c-.968,0-2.891-.288-2.891-2.62Z" transform="translate(33.52 14.392)" fill="#fff" />
                      <path id="Path_1820" data-name="Path 1820" d="M84.24,37.406a.267.267,0,0,0-.3-.3h-.547V35.634h1.583c.613,0,.927.26.927.846v.409a3.013,3.013,0,0,1-.028.434H85.9a2.394,2.394,0,0,1,2.209-1.785,2.236,2.236,0,0,1,.312.027v1.7A3.117,3.117,0,0,0,88,37.243a1.96,1.96,0,0,0-1.9,1.46,4.221,4.221,0,0,0-.149,1.132v2.753H84.24Z" transform="translate(35.02 14.924)" fill="#fff" />
                      <path id="Path_1821" data-name="Path 1821" d="M90.815,35.49a3.016,3.016,0,0,1,3.083,3.3c0,.2-.041.64-.041.64h-4.8A2,2,0,0,0,91.143,41.3a3.161,3.161,0,0,0,1.951-.778l.721,1.2a4.227,4.227,0,0,1-2.794,1.051,3.565,3.565,0,0,1-3.737-3.641,3.431,3.431,0,0,1,3.531-3.642m1.309,2.742a1.349,1.349,0,0,0-1.309-1.4,1.666,1.666,0,0,0-1.7,1.4Z" transform="translate(36.653 14.903)" fill="#fff" />
                      <path id="Path_1822" data-name="Path 1822" d="M93.374,37.426a.267.267,0,0,0-.3-.3h-.545V35.654h1.6c.613,0,.913.285.913.778v.2a1.926,1.926,0,0,1-.027.3h.027a2.678,2.678,0,0,1,2.455-1.444c1.541,0,2.427.8,2.427,2.645V40.85a.273.273,0,0,0,.3.3h.545v1.458h-1.65c-.655,0-.927-.271-.927-.926V38.491c0-.846-.219-1.419-1.092-1.419a1.908,1.908,0,0,0-1.867,1.419,2.893,2.893,0,0,0-.136.926v3.191H93.374Z" transform="translate(38.855 14.904)" fill="#fff" />
                      <path id="Path_1823" data-name="Path 1823" d="M101.89,35.491a2.349,2.349,0,0,1,2.141.981h.028v-.177c0-.314.217-.64.764-.64h1.609v1.472H105.9a.267.267,0,0,0-.3.274v4.787c0,2.385-1.854,3.286-3.668,3.286a5.537,5.537,0,0,1-2.469-.6l.531-1.363a4.5,4.5,0,0,0,1.924.463c1.049,0,1.964-.45,1.964-1.7v-.327c0-.163.013-.369.013-.369h-.027a2.154,2.154,0,0,1-1.935.9c-1.992,0-3.124-1.609-3.124-3.519s1.078-3.463,3.081-3.463m2.033,3.477c0-1.582-.792-2-1.732-2-1.051,0-1.623.764-1.623,1.935,0,1.215.613,2.114,1.731,2.114.832,0,1.624-.477,1.624-2.045" transform="translate(41.493 14.904)" fill="#fff" />
                      <path id="Path_1824" data-name="Path 1824" d="M105.6,37.544h-.9V36.167h.94v-1.9h1.693v1.9h1.582v1.377h-1.582v2.81a1.234,1.234,0,0,0,1.376,1.308,1.688,1.688,0,0,0,.314-.026v1.512a3.046,3.046,0,0,1-.531.043c-.968,0-2.891-.288-2.891-2.62Z" transform="translate(43.968 14.392)" fill="#fff" />
                      <path id="Path_1825" data-name="Path 1825" d="M115.572,43.366h-1.718V39.25c0-.846-.231-1.419-1.1-1.419a1.954,1.954,0,0,0-1.883,1.444,3.119,3.119,0,0,0-.109.9v3.191h-1.731V35.458a.268.268,0,0,0-.3-.3h-.545V33.685h1.65c.64,0,.927.287.927.913v2.441c0,.341-.027.586-.027.586h.027a2.655,2.655,0,0,1,2.387-1.377c1.555,0,2.427.805,2.427,2.647Z" transform="translate(45.428 14.145)" fill="#fff" />
                    </g>
                  </svg>
                </Link>
              </div>
              <div className="footer_menu d-flex flex-column">
                <div className="utility-links d-flex flex-wrap">
                  <div className="footer-link">
                    <Link
                      to={{ pathname: "https://sobi-northamerica.com/terms-use" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={pushOutboundClick}
                    >
                      Terms of Use
                    </Link>
                  </div>
                  <div className="footer-link">
                    <Link
                      to={{ pathname: "https://sobi-northamerica.com/site-privacy-policy " }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={pushOutboundClick}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                  <div className="footer-link">
                    <Link
                      to={{ pathname: "https://sobi-northamerica.com/contact-us " }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={pushOutboundClick}
                    >
                      Contact Us
                    </Link>
                  </div>
                  <div className="footer-link">
                    <Link
                      to={{
                        pathname: "/unsubscribe/",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={pushOutboundClick}
                    >
                      Unsubscribe
                    </Link>
                  </div>
                </div>
                <div className="social-links d-flex">
                  <div className="footer-link social-link facebook">
                    <Link
                      to={{ pathname: "https://www.facebook.com/doptelet/ " }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={addModalLinksFacebookFooter}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>Doptelet Blue FaceBook Icon</title><path id="Facebook" d="M890.6,15.775A12,12,0,1,0,876.734,27.7V19.256h-3.065V15.775h3.065V13.113c0-3.022,1.78-4.7,4.523-4.7a17.122,17.122,0,0,1,2.669.249v2.947h-1.508a1.752,1.752,0,0,0-1.965,1.89v2.263h3.337l-.531,3.482h-2.805v8.444A12.017,12.017,0,0,0,890.6,15.775Z" transform="translate(-866.6 -3.7)" fill="#fff"></path></svg>
                    </Link>
                  </div>
                  <div className="footer-link social-link youtube">
                    <Link
                      to={{ pathname: "https://www.youtube.com/@dopteletavatrombopag5013" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={addModalLinksFacebookFooter}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="19.6" viewBox="0 0 28 19.6"><title>Doptelet Blue YouTube Icon</title><g id="YouTube" transform="translate(-369 -803)"><path id="Path_199" data-name="Path 199" d="M27.415,3.06A3.507,3.507,0,0,0,24.939.585C22.756,0,14,0,14,0S5.244,0,3.06.585A3.507,3.507,0,0,0,.585,3.06,36.427,36.427,0,0,0,0,9.8a36.429,36.429,0,0,0,.585,6.739A3.506,3.506,0,0,0,3.06,19.015C5.244,19.6,14,19.6,14,19.6s8.756,0,10.939-.585a3.506,3.506,0,0,0,2.476-2.475A36.43,36.43,0,0,0,28,9.8a36.427,36.427,0,0,0-.585-6.739" transform="translate(369 803)" fill="#fff"></path><path id="Path_200" data-name="Path 200" d="M27.213,22.007v-8.4l7.274,4.2Z" transform="translate(352.988 794.994)" fill="#02467b"></path></g></svg>
                    </Link>
                  </div>
                  <div className="footer-link social-link instagram">
                    <Link
                      to={{ pathname: "https://www.instagram.com/doptelet_avatrombopag/" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={addModalLinksFacebookFooter}
                    >
                      <svg id="Instagram" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>Doptelet Blue Instagram Icon</title><path id="Path_196" data-name="Path 196" d="M12,2.162c3.2,0,3.584.012,4.849.07a6.665,6.665,0,0,1,2.228.413,3.724,3.724,0,0,1,1.38.9,3.724,3.724,0,0,1,.9,1.38,6.639,6.639,0,0,1,.413,2.228c.058,1.265.07,1.645.07,4.849s-.012,3.584-.07,4.849a6.639,6.639,0,0,1-.413,2.228,3.977,3.977,0,0,1-2.278,2.278,6.639,6.639,0,0,1-2.228.413c-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07a6.639,6.639,0,0,1-2.228-.413,3.724,3.724,0,0,1-1.38-.9,3.724,3.724,0,0,1-.9-1.38,6.665,6.665,0,0,1-.413-2.228c-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849a6.665,6.665,0,0,1,.413-2.228,3.724,3.724,0,0,1,.9-1.38,3.724,3.724,0,0,1,1.38-.9,6.665,6.665,0,0,1,2.228-.413C8.416,2.174,8.8,2.162,12,2.162M12,0C8.741,0,8.332.014,7.052.072A8.785,8.785,0,0,0,4.14.63,5.881,5.881,0,0,0,2.014,2.014,5.881,5.881,0,0,0,.63,4.14,8.785,8.785,0,0,0,.072,7.052C.014,8.332,0,8.741,0,12s.014,3.668.072,4.948A8.785,8.785,0,0,0,.63,19.86a5.881,5.881,0,0,0,1.384,2.126A5.881,5.881,0,0,0,4.14,23.37a8.785,8.785,0,0,0,2.912.558C8.332,23.986,8.741,24,12,24s3.668-.014,4.948-.072a8.785,8.785,0,0,0,2.912-.558,6.133,6.133,0,0,0,3.51-3.51,8.785,8.785,0,0,0,.558-2.912C23.986,15.668,24,15.259,24,12s-.014-3.668-.072-4.948A8.785,8.785,0,0,0,23.37,4.14a5.881,5.881,0,0,0-1.384-2.126A5.881,5.881,0,0,0,19.86.63,8.785,8.785,0,0,0,16.948.072C15.668.014,15.259,0,12,0" fill="#fff"></path><path id="Path_197" data-name="Path 197" d="M12,5.838A6.162,6.162,0,1,0,18.162,12,6.162,6.162,0,0,0,12,5.838M12,16a4,4,0,1,1,4-4,4,4,0,0,1-4,4" fill="#fff"></path><path id="Path_198" data-name="Path 198" d="M19.846,5.594a1.44,1.44,0,1,1-1.44-1.44,1.44,1.44,0,0,1,1.44,1.44" fill="#fff"></path></svg>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="copyright-info">
                <p>
                  {this.props.copyright ? this.props.copyright : 'Doptelet is a registered trademark of AkaRx, Inc., a Sobi company.'}<br />
                  Sobi is a trademark of Swedish Orphan Biovitrum AB (publ)<br />
                  Doptelet Connect is a trademark of AkaRx, Inc.<br />
                  &copy; {this.props.copyyear ? this.props.copyyear : '2024'} Sobi, Inc. &ndash; All rights reserved.
                </p>
                <p style={{ 'whiteSpace': 'pre' }}>
                  {this.props.pp ? this.props.pp : 'PP-15654 v4.0  07/24'}<br />
                  For US Residents Only
                </p>
              </div>
              <Link className="d-none" to="/avatrombopag-tpo-ra/">avatrombopag-tpo-ra</Link>
            </div>
          </Container>
        </div>
      </footer>
    );
  }
}

export default FooterITP;
