import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "../../../css/ISI.scss";

export default function ISI_inline() {
  return (
    <div className="isi-inline-wrapper" id="isi">
      <div className="isi-bar-wrapper">
        <div className="isi-bar">
          <div className="isi-open-link">
            <Container className="h-100">
              <Row className="align-items-center h-100 row">
                <Col className="bar_title text-left">
                  <span>
                    IMPORTANT SAFETY<br /> INFORMATION
                    FOR DOPTELET
                  </span>{" "}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <div className="isi-content">
        <Container>
          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>
                What is DOPTELET<sup>&reg;</sup> (avatrombopag)?
              </strong>
            </p>
            <p>
              DOPTELET is a prescription medicine used to treat low blood
              platelet counts in adults with:
            </p>
            <ul className="padding-bottom">
              <li>
                long-lasting (chronic) liver disease (CLD) who are scheduled to
                have a medical or dental procedure.
              </li>
              <li>
                chronic immune thrombocytopenia (ITP) when other treatments have
                not worked well enough.
              </li>
            </ul>
            <p>
              DOPTELET is not used to make platelet counts normal in adults with
              chronic liver disease or chronic immune thrombocytopenia.
            </p>
            <p className="no-padding-bottom">
              It is not known if DOPTELET is safe and effective in children.
            </p>
          </section>

          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>
                Before you take DOPTELET, tell your healthcare provider about
                all of your medical conditions, including if you:
              </strong>
            </p>
            <ul>
              <li>have ever had a blood clot.</li>
              <li>
                are pregnant or plan to become pregnant. DOPTELET may harm your
                unborn baby. Tell your healthcare provider if you become
                pregnant or think you may be pregnant during treatment with
                DOPTELET.
              </li>
              <li>
                are breastfeeding or plan to breastfeed. It is not known if
                DOPTELET passes into your breast milk. Do not breastfeed during
                your treatment with DOPTELET and for at least 2 weeks after the
                last dose. Talk to your healthcare provider about the best way
                to feed your baby during this time.
              </li>
            </ul>
          </section>

          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>
                Tell your healthcare provider about all of the medicines you
                take,
              </strong>{" "}
              including prescription and over-the-counter medicines, vitamins,
              and herbal supplements. DOPTELET may affect the way other
              medicines work, and other medicines may affect the way DOPTELET
              works.
            </p>
          </section>

          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>How should I take DOPTELET?</strong>
            </p>
            <ul>
              <li>
                Take DOPTELET exactly as your healthcare provider tells you to
                take it.
              </li>
              <li>
                Your healthcare provider will tell you how much DOPTELET to take
                and when to start taking it.
              </li>
              <li>
                Your healthcare provider may change your dose of DOPTELET
                depending on your blood platelet counts.
              </li>
              <li>Take DOPTELET with food.</li>
              <li>
                If you take DOPTELET to treat your low blood platelet counts due
                to chronic liver disease before a medical or dental procedure,
                your healthcare provider will check your platelet count before
                treatment and on the day of your scheduled procedure.
              </li>
              <li>
                If you take DOPTELET to treat your low blood platelet counts due
                to chronic immune thrombocytopenia, your healthcare provider
                will check your platelet count before, during and for at least 4
                weeks after stopping your treatment with DOPTELET.
              </li>
              <li>
                If you are taking DOPTELET prior to a scheduled medical
                procedure and you miss a dose, contact your healthcare provider
                for further dosing instructions.
              </li>
              <li>
                If you are taking DOPTELET for chronic immune thrombocytopenia
                and you miss a dose of DOPTELET, take it as soon as you
                remember. Do not take 2 doses at one time to make up for a
                missed dose. Take your next dose at your usual scheduled time.
              </li>
              <li>
                If you take too much DOPTELET, call your healthcare provider or
                go to the nearest hospital emergency room right away.
              </li>
            </ul>
          </section>

          <section className="mb-3">
            <p>
              <strong>What are the possible side effects of DOPTELET?</strong>
            </p>
            <p className="list-header">
              <strong>
                DOPTELET may cause serious side effects, including blood clots.
              </strong>{" "}
              People with chronic liver disease or chronic immune
              thrombocytopenia and people with certain blood clotting conditions
              may have an increased risk of developing blood clots. Tell your
              healthcare provider right away if you have signs and symptoms of a
              blood clot, including:
            </p>
            <div className="flex-list-wrapper mb-3">
              <div className="flex-list">
                <ul>
                  <li>swelling, pain, or tenderness in your legs</li>
                  <li>shortness of breath</li>
                  <li>chest pain</li>
                </ul>
              </div>
              <div className="flex-list">
                <ul>
                  <li>fast heartbeat</li>
                  <li>stomach (abdominal) pain or tenderness</li>
                </ul>
              </div>
            </div>

            <p className="list-header">
              <strong>
                The most common side effects of DOPTELET in people with chronic
                liver disease are:{" "}
              </strong>
            </p>
            <div className="flex-list-wrapper mb-3">
              <div className="flex-list">
                <ul>
                  <li>fever</li>
                  <li>stomach (abdominal) pain</li>
                  <li>nausea</li>
                </ul>
              </div>
              <div className="flex-list">
                <ul>
                  <li>headache</li>
                  <li>tiredness</li>
                  <li>swelling of the hands or feet</li>
                </ul>
              </div>
            </div>

            <p className="list-header">
              <strong>
                The most common side effects of DOPTELET when used to treat low
                blood platelet counts in adults with chronic immune
                thrombocytopenia (ITP) are:
              </strong>
            </p>
            <div className="flex-list-wrapper mb-1">
              <div className="flex-list">
                <ul>
                  <li>headache</li>
                  <li>tiredness</li>
                  <li>bruising</li>
                  <li>nosebleed</li>
                  <li>upper respiratory tract infection</li>
                </ul>
              </div>
              <div className="flex-list">
                <ul>
                  <li>joint pain</li>
                  <li>bleeding gums</li>
                  <li>purple or red spots on your skin</li>
                  <li>runny nose</li>
                </ul>
              </div>
            </div>

            <p className="mb-4">These are not all the possible side effects of DOPTELET.</p>
            <p>
              Call your doctor for medical advice about side effects. You may
              report side effects to FDA at{" "}
              <a
                href="tel:+18003321088"
                target="_blank"
                rel="noopener noreferrer"
                className="light-font no-break-mobile"
              >
                1-800-FDA-1088
              </a>
              .
            </p>
          </section>

          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>How should I store DOPTELET?</strong>
            </p>
            <ul className="no-padding-bottom">
              <li>
                Store DOPTELET at room temperature between 68°F to 77°F (20°C to
                25°C).
              </li>
              <li>Store DOPTELET tablets in the original package.</li>
            </ul>
            <p className="no-padding-bottom">
              <strong>
                Keep DOPTELET and all medicines out of the reach of children.
              </strong>
            </p>
          </section>

          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>
                General information about the safe and effective use of
                DOPTELET.
              </strong>
            </p>
            <p className="no-padding-bottom">
              Medicines are sometimes prescribed for purposes other than those
              listed in a Patient Information leaflet. Do not use DOPTELET for a
              condition for which it was not prescribed. Do not give DOPTELET to
              other people, even if they have the same symptoms that you have.
              It may harm them. You can ask your pharmacist or healthcare
              provider for information about DOPTELET that is written for health
              professionals.
            </p>
          </section>

          <section className="mb-3">
            <p className="no-padding-bottom">
              <strong>What are the ingredients in DOPTELET?</strong>
            </p>
            <p className="no-padding-bottom">
              <strong>Active ingredient:</strong> avatrombopag
            </p>
            <p className="no-padding-bottom">
              <strong>Inactive ingredients:</strong> lactose monohydrate,
              colloidal silicon dioxide, crospovidone, magnesium stearate and
              microcrystalline cellulose. Tablet coating film: polyvinyl
              alcohol, talc, polyethylene glycol, titanium dioxide and ferric
              oxide yellow.
            </p>
          </section>

          <section>
            <p>
              <strong>
                Please see full{" "}
                <Link
                  to={{
                    pathname:
                      "/themes/pdf/prescribing-information.pdf",
                  }}
                  target="_blank"
                >
                  Prescribing Information
                </Link>
                , including the{" "}
                <Link
                  to={{
                    pathname:
                      "/themes/pdf/patient-information-leaflet.pdf",
                  }}
                  target="_blank"
                >
                  Patient Information Leaflet
                </Link>
                .
              </strong>
            </p>
            <p className="no-padding-bottom">
              This Patient Information has been approved by the U.S. Food and
              Drug Administration.
            </p>
          </section>
        </Container>
      </div>
    </div>
  );
}
