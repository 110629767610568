import React, { useEffect } from "react";
import { helmetJsonLdProp } from "react-schemaorg";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap/esm/index";

// Import Components
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import TextOnlyHeroHeader from "../../components/HeroHeader/TextOnlyHeroHeader";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";

// Import Styles
import "../../css/ThankYou.scss";

function RecruitThankYou() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Thank You | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/thank-you/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
            ],
          }),
        ]}
      >

      <meta name="robots" content="noindex" />

      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap">
        <TextOnlyHeroHeader
          markup={
            <>
              <h1>Thank you for your interest in inspiring others!</h1>
              <p>We’ll reach out with more information soon.</p>
            </>
          }
        />
        <section className="thank-you pt-5 pb-5">
          <Container className="max-width-1024">
            <div className="flex-container justify-content-center">
              <picture>
                <source
                  srcSet="/images/4.1_thank_you.png 1x, /images/4.1_thank_you_@2X.png 2x"
                  type="image/png" />
                <img src="/images/4.1_thank_you.png" alt=""/>
              </picture>
            </div>
          </Container>
        </section>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default RecruitThankYou;
