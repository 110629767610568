import React from "react";
import { Container } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

import "../../../css/ISI.scss";

const startScrollTo = (event) => {
  const currentPath = window.location.pathname;
  const href = event.target.getAttribute('href');
  const hrefParts = href.split('#');
  if (hrefParts[0] === currentPath) {
    event.preventDefault();
    const element = document.getElementById(hrefParts[1]);
    scrollToElement(element);
  }
}

const scrollToElement = (element) => {
  setTimeout(() => {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, 1000);
}

export default function ISIITP_inline() {
  return (
    <div className="isi-inline-wrapper isi-inline-itp" id="isi">
      <div className="isi-content">
        <div className="inline-isi-header">
          <Container><p><strong>INDICATION & IMPORTANT SAFETY INFORMATION</strong></p></Container>
        </div>
        <Container>
          <p><strong>What is DOPTELET</strong> (avatrombopag)<strong>?</strong></p>
          <p>DOPTELET is a prescription medicine used to help treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) when other treatments have not worked well enough. It is not known if DOPTELET is safe or works in children.</p>
          <p><strong><em>Who should not receive DOPTELET?</em></strong></p>
          <p>You should not receive DOPTELET if you have ever had a blood clot, are pregnant, plan to become pregnant or plan to breastfeed. It is not known if DOPTELET passes into breast milk or if DOPTELET may have unintended side effects for your unborn baby. Do not breastfeed during your treatment with DOPTELET and for at least 2 weeks after the last dose.</p>
          <ul className="mb-3">
            <li className="bullet-circle">
              <span className="text-underline d-block mb-2">You Should Know:</span>
              <ul>
                <li>
                  <span className="d-block mb-2">Signs and symptoms of a blood clot could include:</span>
                  <ul>
                    <li>Swelling, pain, or tenderness in your legs</li>
                    <li>Shortness of breath</li>
                    <li>Chest pain</li>
                    <li>Fast heartbeat</li>
                    <li>Stomach pain or tenderness</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <p><strong>You should call your healthcare provider or get medical help right away if you have any of the signs or symptoms of a blood clot after getting DOPTELET. They can be life threatening or cause death.</strong></p>
          <p>Before taking DOPTELET, you should tell your healthcare provider about all your medical conditions and medicines including vitamins, supplements, and herbal remedies.</p>
          <p id="isi-side-effects"><strong><em>What are the possible side effects of DOPTELET?</em></strong></p>
          <p>For patients with chronic ITP, the most common side effects are headache, fatigue, bruising, nosebleeds, upper respiratory tract infection, joint pain, bleeding gums, purple or red spots on your skin (petechiae), and runny nose.</p>
          <p><u>You Should Know</u>: These are not all of the possible side effects for DOPTELET. For more information, ask your healthcare provider or review the Prescribing Information at <strong><a href="https://www.doptelet.com/doptelet.pdf" target="_blank">https://www.doptelet.com/doptelet.pdf</a></strong>.</p>
          <p>If you suspect that you have experienced a side effect, call your healthcare provider. You may also report them to the US Food and Drug Administration (FDA) at <a href="tel:1-800-332-1088" className="no-break"><strong>1-800-FDA-1088</strong></a>.</p>
          <p><strong>
            For further information, please see full{" "}
            <Link
              to={{
                pathname:
                  "/themes/pdf/prescribing-information.pdf",
              }}
              target="_blank"
            >
              Prescribing Information
            </Link>
            , including the{" "}
            <Link
              to={{
                pathname:
                  "/themes/pdf/patient-information-leaflet.pdf",
              }}
              target="_blank"
            >
              Patient Information Leaflet
            </Link>
            .</strong>
          </p>

        </Container>
      </div>
    </div>
  );
}
