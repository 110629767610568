import React, { useState, useEffect } from "react";

import { Container, Dropdown, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SpeakerButton from "../shared-ui/Buttons/SpeakerButton"
import {
  pushNavHeaderClick,
  pushDownloadEvent,
  pushOutboundClick,
  pushIndicationClick,
  pushLogoClick,
} from "../../assets/datalayerFunctions";

import "../../css/ITPNavigation.scss";

function CLDNavigation(props) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  let location = useLocation();
  let elementToScrollTo;

  const startScrollTo = (event) => {
    const currentPath = window.location.pathname;
    const href = event.target.getAttribute('href');
    const hrefParts = href.split('#');
    if (hrefParts[0] === currentPath) {
      // event.preventDefault();
      const element = document.getElementById(hrefParts[1]);
      scrollToElement(element);
    }
  }

  const scrollToElement = (element) => {
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 1000);
  }

  const elementIsVisibleInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const onScroll = e => {
      // Check if element is in viewport.
      // Stuff for sidebar isi scrolling.
      const isi = document.getElementById('isi');
      const miniIsi = document.getElementById('mini-isi');
      const isiVisible = elementIsVisibleInViewport(isi);
      const sidebarIsiCollapse = 1280;
      const currentScrollTop = (e.target.documentElement.scrollTop < 0) ? 0 : e.target.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        setIsScrollingUp(false);
        miniIsi.classList.add('isi-minimized')
      }
      else if (currentScrollTop < lastScrollTop) {
        setIsScrollingUp(true);
      }
      lastScrollTop = currentScrollTop;


      if (isiVisible && window.innerWidth <= sidebarIsiCollapse) {
        miniIsi.style.display = 'none';
      }
      else {
        miniIsi.style.display = '';
      }
    };
    window.addEventListener('scroll', onScroll);

    // Scroll to hash when page loads.
    let hash = window.location.hash
    if (hash) {
      hash = hash.slice(1);
      const element = document.getElementById(hash);
      scrollToElement(element);
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [elementToScrollTo]);

  return (
    <header className={`header ${isNavOpen ? "navOpen" : ""}`}>
      <div className="utility-nav cld-nav">
        <Container>
          <div className="menu_wrap-mobile">
            <ul className="cld-nav-mobile">
              <li>
                <div
                  onClick={(event) => {
                    if (
                      document
                        .querySelector(".header")
                        .classList.contains("navOpen")
                    ) {
                      setIsNavOpen(!isNavOpen);
                      document
                        .querySelector("body")
                        .classList.contains("nav-open")
                        ? document
                          .querySelector("body")
                          .classList.remove("nav-open")
                        : document
                          .querySelector("body")
                          .classList.add("nav-open");
                    }
                    var top =
                      document.getElementById("about-doptelet").offsetTop -
                      document.querySelector(".header").offsetHeight;
                    //show 10 pixels down the border
                    window.scrollTo({
                      left: 0,
                      top: top,
                      behavior: "smooth",
                    });
                    pushNavHeaderClick(event);
                  }}
                >
                  About Doptelet
                </div>
              </li>
              <li>
                <div
                  onClick={(event) => {
                    if (
                      document
                        .querySelector(".header")
                        .classList.contains("navOpen")
                    ) {
                      setIsNavOpen(!isNavOpen);
                      document
                        .querySelector("body")
                        .classList.contains("nav-open")
                        ? document
                          .querySelector("body")
                          .classList.remove("nav-open")
                        : document
                          .querySelector("body")
                          .classList.add("nav-open");
                    }
                    var top =
                      document.getElementById("taking-doptelet-cld").offsetTop -
                      document.querySelector(".header").offsetHeight;
                    //show 10 pixels down the border
                    window.scrollTo({
                      left: 0,
                      top: top,
                      behavior: "smooth",
                    });
                    pushNavHeaderClick(event);
                  }}
                >
                  Taking Doptelet
                </div>
              </li>
              <li>
                <div
                  onClick={(event) => {
                    if (
                      document
                        .querySelector(".header")
                        .classList.contains("navOpen")
                    ) {
                      setIsNavOpen(!isNavOpen);
                      document
                        .querySelector("body")
                        .classList.contains("nav-open")
                        ? document
                          .querySelector("body")
                          .classList.remove("nav-open")
                        : document
                          .querySelector("body")
                          .classList.add("nav-open");
                    }
                    var top =
                      document.getElementById("getting-doptelet").offsetTop -
                      document.querySelector(".header").offsetHeight;
                    //show 10 pixels down the border
                    window.scrollTo({
                      left: 0,
                      top: top,
                      behavior: "smooth",
                    });
                    pushNavHeaderClick(event);
                  }}
                >
                  Getting Doptelet
                </div>
              </li>
            </ul>
          </div>
          <ul className="">
            <li>
              <Link
                to="#isi"
                onClick={() => {
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                  setTimeout(function() {
                    document.getElementById("expand").click();
                  }, 350);
                }}
              >
                Important Safety Information
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "/themes/pdf/prescribing-information.pdf",
                }}
                target="_blank"
                onClick={(event) => {
                  pushDownloadEvent(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                Prescribing Information
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "/themes/pdf/patient-brief-summary.pdf",
                }}
                target="_blank"
                onClick={(event) => {
                  pushDownloadEvent(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                Important Facts
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "https://doptelethcp.com/" }}
                target="_blank"
                onClick={(event) => {
                  pushOutboundClick(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                For Physicians
              </Link>
            </li>
            <li className="condition-selector">
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  Select Condition
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/home/" onClick={pushIndicationClick}>
                    Immune Thrombocytopenia
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/cld/"
                    id="is-active"
                    onClick={pushIndicationClick}
                  >
                    Chronic Liver Disease
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Container>
      </div>

      <div className="nav_wrap">
        <Container className="menu-container">
          <Row className="align-items-center">
            <Col xs={3} className="logo-wrapper">
              <Link
                to="/cld/"
                onClick={(event) => {
                  pushLogoClick(event);
                  if (
                    document
                      .querySelector(".header")
                      .classList.contains("navOpen")
                  ) {
                    setIsNavOpen(!isNavOpen);
                    document
                      .querySelector("body")
                      .classList.contains("nav-open")
                      ? document
                        .querySelector("body")
                        .classList.remove("nav-open")
                      : document
                        .querySelector("body")
                        .classList.add("nav-open");
                  }
                }}
              >
                <img
                  alt="Doptelet avatrombopag Tablets Logo"
                  src="/images/doptelet-avatrombopag-tablets-logo.svg"
                />
              </Link>
              <div className="speaker-button">
                <SpeakerButton />
              </div>
            </Col>
            <Col xs={9} className="link-wrapper">
              <div className="menu_wrap cld-menu">
                <ul>
                  <li title="About Doptelet">
                    <div
                      onClick={(event) => {
                        var top =
                          document.getElementById("about-doptelet").offsetTop -
                          document.querySelector(".header").offsetHeight;
                        //show 10 pixels down the border
                        window.scrollTo({
                          left: 0,
                          top: top,
                          behavior: "smooth",
                        });
                        pushNavHeaderClick(event);
                      }}
                    >
                      About Doptelet
                    </div>
                  </li>
                  <li title="Taking Doptelet">
                    <div
                      onClick={(event) => {
                        var top =
                          document.getElementById("taking-doptelet-cld").offsetTop -
                          document.querySelector(".header").offsetHeight;
                        //show 10 pixels down the border
                        window.scrollTo({
                          left: 0,
                          top: top,
                          behavior: "smooth",
                        });
                        pushNavHeaderClick(event);
                      }}
                    >
                      Taking Doptelet
                    </div>
                  </li>
                  <li title="Getting Doptelet">
                    <div
                      onClick={(event) => {
                        var top =
                          document.getElementById("getting-doptelet")
                            .offsetTop -
                          document.querySelector(".header").offsetHeight;
                        //show 10 pixels down the border
                        window.scrollTo({
                          left: 0,
                          top: top,
                          behavior: "smooth",
                        });
                        pushNavHeaderClick(event);
                      }}
                    >
                      Getting Doptelet
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div
            className="menu_btn"
            onClick={() => {
              setIsNavOpen(!isNavOpen);
              document.querySelector("body").classList.contains("nav-open")
                ? document.querySelector("body").classList.remove("nav-open")
                : document.querySelector("body").classList.add("nav-open");
            }}
          >
            <span className="bars">
              <span></span>
              <span></span>
              <span></span>
            </span>
            <div>Menu</div>
            <div>Close</div>
          </div>
        </Container>
      </div>
      <div
        className="open-nav-overlay"
        onClick={() => {
          setIsNavOpen(!isNavOpen);
          document.querySelector("body").classList.contains("nav-open")
            ? document.querySelector("body").classList.remove("nav-open")
            : document.querySelector("body").classList.add("nav-open");
        }}
      ></div>
    </header>
  );
}

export default CLDNavigation;
